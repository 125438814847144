import * as Toast from '@radix-ui/react-toast'
import { Pencil } from 'lucide-react'
import { useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { Label } from '../ui/label'

interface RegionEditDialogProps {
  id: number
  currentName: string
  currentObservation: string
  onRegionUpdate: () => void
}

export function RegionEditDialog({
  id,
  currentName,
  currentObservation,
  onRegionUpdate,
}: RegionEditDialogProps) {
  const [openModel, setOpenModel] = useState(false)
  const [name, setName] = useState(currentName)
  const [observation, setObservation] = useState(currentObservation)
  const [toastOpen, setToastOpen] = useState(false)
  const [toastType, setToastType] = useState('success')
  const [toastMessage, setToastMessage] = useState('')
  const [loading, setLoading] = useState(false)

  function handleToOpenModel() {
    setOpenModel(true)
    setName(currentName)
    setObservation(currentObservation)
  }

  async function handleSaveChanges() {
    const updatedRegion = {
      data: {
        Name: name,
        Observation: observation,
      },
    }

    setLoading(true)
    try {
      const route = import.meta.env.VITE_URL_ENDPOINT
      const response = await fetch(`${route}/api/regions/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedRegion),
      })

      if (!response.ok) {
        throw new Error('Failed to update region')
      }
      setOpenModel(false)
      onRegionUpdate()
      setToastType('success')
      setToastMessage('Região editada com sucesso!')
      setToastOpen(true)
    } catch (error) {
      console.error('Failed to update region:', error)
      setToastOpen(true)
      setToastType('error')
      setToastMessage('Falha ao atualizar a região!')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Toast.Provider swipeDirection="right">
        <Dialog open={openModel} onOpenChange={setOpenModel}>
          <DialogTrigger asChild>
            <Button
              onClick={handleToOpenModel}
              className="rounded bg-gray-100 p-2 hover:bg-gray-200"
            >
              <Pencil className="text-gray-700" />
            </Button>
          </DialogTrigger>
          <DialogContent className="rounded-md bg-white p-4 shadow-lg sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle className="text-xl font-bold">
                Editar Região
              </DialogTitle>
              <DialogDescription className="text-sm text-gray-500">
                Após editar basta clicar em SALVAR
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Nome
                </Label>
                <Input
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="observation" className="text-right">
                  Observação
                </Label>
                <Input
                  id="observation"
                  value={observation}
                  onChange={(e) => setObservation(e.target.value)}
                  className="col-span-3"
                />
              </div>
            </div>
            <DialogFooter>
              <Button
                type="button"
                onClick={handleSaveChanges}
                disabled={loading}
              >
                {loading ? 'Salvando...' : 'Salvar Alterações'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Toast.Root
          open={toastOpen}
          onOpenChange={setToastOpen}
          className={`fixed bottom-0 right-0 m-6 rounded-md p-4 shadow-md ${
            toastType === 'success'
              ? 'bg-green-500 text-white'
              : 'bg-red-500 text-white'
          }`}
        >
          <Toast.Description>{toastMessage}</Toast.Description>
          <Toast.Close className="absolute right-1 top-1" />
        </Toast.Root>
        <Toast.Viewport />
      </Toast.Provider>
    </div>
  )
}
