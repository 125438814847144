// OwnerSelector.tsx

import { useEffect, useState } from 'react'

import type {
  Owner,
  PropertyFormData,
} from '@/components/form-property/property-types'
import { CreateOwnerDialog } from '@/components/owner/OwnerCreateDialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'
import { useDebounce } from '@/hooks/useDebounce'

interface OwnerSelectorProps {
  setFormData: React.Dispatch<React.SetStateAction<PropertyFormData>>
  selectedOwner?: Owner | null
}

export function OwnerSelector({
  setFormData,
  selectedOwner,
}: OwnerSelectorProps) {
  const { toast } = useToast()
  const [ownerSelected, setOwnerSelected] = useState(false)
  const [ownerSearchTerm, setOwnerSearchTerm] = useState('')
  const [filteredOwners, setFilteredOwners] = useState<Owner[]>([])
  const debouncedSearchTerm = useDebounce(ownerSearchTerm, 500)
  const route = import.meta.env.VITE_URL_ENDPOINT

  // Ajuste para refletir o proprietário selecionado externo
  useEffect(() => {
    if (selectedOwner) {
      setOwnerSearchTerm(selectedOwner.attributes.name)
      setOwnerSelected(true)
    }
  }, [selectedOwner])

  useEffect(() => {
    if (ownerSelected) {
      return
    }
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
      const fetchFilteredOwners = async () => {
        try {
          const response = await fetch(
            `${route}/api/owners?filters[name][$containsi]=${debouncedSearchTerm}`,
          )
          const result = await response.json()
          setFilteredOwners(result.data)
        } catch (error) {
          console.error('Erro ao buscar proprietários:', error)
          toast({
            title: 'Erro',
            description: 'Erro ao buscar proprietários.',
            variant: 'destructive',
          })
        }
      }

      fetchFilteredOwners()
    } else {
      setFilteredOwners([])
    }
  }, [debouncedSearchTerm, route, toast, ownerSelected])

  const handleOwnerSelect = (owner: Owner) => {
    setFormData((prevState) => ({
      ...prevState,
      owner: owner.id,
    }))
    setOwnerSearchTerm(owner.attributes.name)
    setFilteredOwners([])
    setOwnerSelected(true)
  }

  const handleOwnerCreate = (owner: Owner) => {
    setFormData((prevState) => ({
      ...prevState,
      owner: owner.id,
    }))
    setOwnerSearchTerm(owner.attributes.name)
    setFilteredOwners([])
    setOwnerSelected(true)
  }

  return (
    <div className="mb-5">
      <Label htmlFor="owner" className="text-gray-600">
        Proprietário
      </Label>
      <div className="flex items-center">
        <Input
          type="text"
          id="owner"
          name="owner"
          value={ownerSearchTerm}
          onChange={(e) => {
            setOwnerSearchTerm(e.target.value)
            setOwnerSelected(false)
          }}
          placeholder="Digite o nome do proprietário"
          className="w-full"
        />
        <CreateOwnerDialog
          onOwnerCreate={handleOwnerCreate}
          trigger={
            <span className="ml-2 cursor-pointer text-blue-500 hover:underline">
              Adicionar Proprietário
            </span>
          }
        />
      </div>
      {debouncedSearchTerm &&
        debouncedSearchTerm.length >= 3 &&
        !ownerSelected && (
          <div className="border border-gray-300 bg-white shadow-md">
            {filteredOwners.length > 0 ? (
              filteredOwners.map((owner) => (
                <div
                  key={owner.id}
                  onClick={() => handleOwnerSelect(owner)}
                  className="cursor-pointer p-2 hover:bg-gray-100"
                >
                  {owner.attributes.name}
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-500">
                Proprietário não encontrado
              </div>
            )}
          </div>
        )}
    </div>
  )
}
