// PhotoUpload.tsx
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'

interface PhotoUploadProps {
  selectedFiles: File[]
  setSelectedFiles: React.Dispatch<React.SetStateAction<File[]>>
}

export function PhotoUpload({
  selectedFiles,
  setSelectedFiles,
}: PhotoUploadProps) {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).slice(0, 10)
      setSelectedFiles(filesArray)
    }
  }

  return (
    <div className="mt-6">
      <Label htmlFor="photos" className="text-gray-600">
        Fotos (máximo 10)
      </Label>
      <Input
        type="file"
        id="photos"
        name="photos"
        multiple
        accept="image/*"
        onChange={handleFileChange}
        className="mt-1 block w-full"
      />
      {selectedFiles.length > 0 && (
        <div className="mt-2">
          <p>Arquivos selecionados:</p>
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
