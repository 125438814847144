import * as Toast from '@radix-ui/react-toast'
import { useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import { Textarea } from '../ui/textarea'

interface CreateRegionDialogProps {
  onRegionCreate: () => void
}

export function CreateRegionDialog({
  onRegionCreate,
}: CreateRegionDialogProps) {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [observation, setObservation] = useState('')
  const [toastOpen, setToastOpen] = useState(false)
  const [toastType, setToastType] = useState<'success' | 'error'>('success')
  const [toastMessage, setToastMessage] = useState('')

  async function handleCreateRegion() {
    const newRegion = {
      data: {
        Name: name,
        Observation: observation,
      },
    }

    try {
      const route = import.meta.env.VITE_URL_ENDPOINT
      const response = await fetch(`${route}/api/regions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newRegion),
      })

      if (!response.ok) {
        throw new Error('Failed to create region')
      }

      setOpen(false)
      setName('')
      setObservation('')
      onRegionCreate()
      setToastType('success')
      setToastMessage('Região criada com sucesso!')
      setToastOpen(true)
    } catch (error: unknown) {
      console.error('Failed to create region:', error)
      setToastOpen(true)
      setToastType('error')
      setToastMessage('Falha ao criar a região!')
    }
  }

  return (
    <div>
      <Toast.Provider swipeDirection="right">
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <Button className="mt-2 flex w-full items-center justify-center rounded-md bg-gray-900 p-2 text-white">
              <p>Cadastrar Região</p>
            </Button>
          </DialogTrigger>
          <DialogContent className="rounded-md bg-white p-4 shadow-lg sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle className="text-xl font-bold">
                Criar Região
              </DialogTitle>
              <DialogDescription className="text-sm text-gray-500">
                Preencha as informações e clique em salvar
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Nome
                </Label>
                <Input
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="observation" className="text-right">
                  Observação
                </Label>
                <Textarea
                  id="observation"
                  value={observation}
                  onChange={(e) => setObservation(e.target.value)}
                  className="col-span-3"
                />
              </div>
            </div>
            <DialogFooter>
              <Button type="button" onClick={handleCreateRegion}>
                Salvar
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Toast.Root
          open={toastOpen}
          onOpenChange={setToastOpen}
          className={`fixed bottom-0 right-0 m-6 rounded-md p-4 shadow-md ${
            toastType === 'success'
              ? 'bg-green-500 text-white'
              : 'bg-red-500 text-white'
          }`}
        >
          <Toast.Description>{toastMessage}</Toast.Description>
          <Toast.Close className="absolute right-1 top-1" />
        </Toast.Root>
        <Toast.Viewport />
      </Toast.Provider>
    </div>
  )
}
