import * as Toast from '@radix-ui/react-toast'
import { Trash } from 'lucide-react'
import { useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import { Button } from '../ui/button'
import { OwnerEditDialog } from './OwnerEditDialog'
import { OwnerProperties } from './OwnerProperties'

export function OwnerCard({
  id,
  name,
  telephone,
  cell_phone,
  email,
  observation,
  onOwnerUpdate,
}: {
  id: number
  name: string
  telephone: string
  cell_phone: string
  email: string
  observation: string
  onOwnerUpdate: () => void
}) {
  const [toastOpen, setToastOpen] = useState(false)
  const [toastType, setToastType] = useState<'success' | 'error'>('success')
  const [toastMessage, setToastMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const route = import.meta.env.VITE_URL_ENDPOINT
  async function handleDeleteOwner() {
    setLoading(true)
    try {
      const response = await fetch(`${route}/api/owners/${id}`, {
        method: 'DELETE',
      })

      if (!response.ok) {
        throw new Error('Failed to delete owner')
      }
      onOwnerUpdate()
      setToastType('success')
      setToastMessage('Proprietário excluído com sucesso!')
      setToastOpen(true)
    } catch (error) {
      console.error('Failed to delete owner:', error)
      setToastOpen(true)
      setToastType('error')
      setToastMessage('Falha ao excluir o proprietário!')
    } finally {
      setLoading(false)
      setOpenConfirm(false)
    }
  }

  return (
    <div className="w-full p-4">
      <Toast.Provider swipeDirection="right">
        <div className="flex items-center justify-between rounded-md bg-gray-100 p-6 shadow-sm">
          <div className="mr-8 flex flex-col">
            <h2 className="text-lg font-bold text-gray-900">{name}</h2>
            <p className="text-sm text-gray-600">{telephone}</p>
            <p className="text-sm text-gray-600">{cell_phone}</p>
            <p className="text-sm text-gray-600">{email}</p>
            <p className="text-sm text-gray-600">{observation}</p>
          </div>
          <div className="flex items-center space-x-2">
            <OwnerProperties />
            <OwnerEditDialog
              id={id}
              currentName={name}
              currentTelephone={telephone}
              currentCellPhone={cell_phone}
              currentEmail={email}
              currentObservation={observation}
              onOwnerUpdate={onOwnerUpdate}
            />
            <Dialog open={openConfirm} onOpenChange={setOpenConfirm}>
              <DialogTrigger asChild className="bg-gray-100 hover:bg-gray-200">
                <Button
                  className="rounded p-2"
                  onClick={() => setOpenConfirm(true)}
                >
                  <Trash className="text-gray-700" />
                </Button>
              </DialogTrigger>
              <DialogContent className="rounded-md bg-white p-4 shadow-lg sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle className="text-xl font-bold">
                    Confirmar Exclusão
                  </DialogTitle>
                  <DialogDescription className="text-sm text-gray-500">
                    Tem certeza que deseja excluir este proprietário? Esta ação
                    não pode ser desfeita.
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                  <Button
                    className="bg-red-600 text-white"
                    onClick={handleDeleteOwner}
                    disabled={loading}
                  >
                    {loading ? 'Excluindo...' : 'Excluir'}
                  </Button>
                  <Button
                    className="bg-gray-300"
                    onClick={() => setOpenConfirm(false)}
                    disabled={loading}
                  >
                    Cancelar
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        </div>

        <Toast.Root
          open={toastOpen}
          onOpenChange={setToastOpen}
          duration={5000} // Duração do Toast
          className={`fixed bottom-0 right-0 m-6 rounded-md p-4 shadow-md ${
            toastType === 'success'
              ? 'bg-green-500 text-white'
              : 'bg-red-500 text-white'
          }`}
        >
          <Toast.Description>{toastMessage}</Toast.Description>
          <Toast.Close className="absolute right-1 top-1" />
        </Toast.Root>
        <Toast.Viewport />
      </Toast.Provider>
    </div>
  )
}
