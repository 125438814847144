import * as Toast from '@radix-ui/react-toast'
import { useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { Label } from '../ui/label'

interface CreateUserDialogProps {
  onUserCreate: () => void
}

export function CreateUserDialog({ onUserCreate }: CreateUserDialogProps) {
  const [open, setOpen] = useState(false)
  const [username, setUsername] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState('BROKER')
  const [toastOpen, setToastOpen] = useState(false)
  const [toastType, setToastType] = useState<'success' | 'error'>('success')
  const [toastMessage, setToastMessage] = useState('')

  async function handleCreateUser() {
    const newUser = {
      username,
      email,
      password,
      role: 2,
      confirmed: true,
      blocked: false,
      collaborator: role,
    }

    try {
      const route = import.meta.env.VITE_URL_ENDPOINT
      const response = await fetch(`${route}/api/users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newUser),
      })

      if (!response.ok) {
        const errorData = await response.json()
        console.error('Error data:', errorData)
        const errorMessage =
          errorData.message && errorData.message[0].messages
            ? errorData.message[0].messages[0].message
            : 'Falha ao criar o usuário'
        throw new Error(errorMessage)
      }

      setOpen(false)
      setUsername('')
      setEmail('')
      setPassword('')
      setRole('BROKER')
      onUserCreate()
      setToastType('success')
      setToastMessage('Usuário criado com sucesso!')
      setToastOpen(true)
    } catch (error: unknown) {
      console.error('Failed to create user:', error)
      setToastOpen(true)
      setToastType('error')
      setToastMessage(
        error instanceof Error && error.message.includes('already exists')
          ? 'Usuário já existe!'
          : 'Falha ao criar o usuário!',
      )
    }
  }

  return (
    <div>
      <Toast.Provider swipeDirection="right">
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <Button className="mt-2 flex w-full items-center justify-center rounded-md bg-gray-900 p-2 text-white">
              <p>Cadastrar Usuário</p>
            </Button>
          </DialogTrigger>
          <DialogContent className="rounded-md bg-white p-4 shadow-lg sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle className="text-xl font-bold">
                Criar Usuário
              </DialogTitle>
              <DialogDescription className="text-sm text-gray-500">
                Preencha as informações e clique em salvar
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="username" className="text-right">
                  Nome
                </Label>
                <Input
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="email" className="text-right">
                  Email
                </Label>
                <Input
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="password" className="text-right">
                  Senha
                </Label>
                <Input
                  id="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="col-span-3"
                />
              </div>
              <div className="flex w-full items-center justify-center gap-4">
                <Button
                  className={`text-black ${
                    role === 'ADMIN'
                      ? 'bg-blue-600 hover:bg-blue-700'
                      : 'bg-gray-300 hover:bg-gray-400'
                  }`}
                  onClick={() => setRole('ADMIN')}
                >
                  Administrador
                </Button>
                <Button
                  className={`text-black ${
                    role === 'BROKER'
                      ? 'bg-green-600 hover:bg-green-700'
                      : 'bg-gray-300 hover:bg-gray-400'
                  }`}
                  onClick={() => setRole('BROKER')}
                >
                  Corretor
                </Button>
              </div>
            </div>
            <DialogFooter>
              <Button type="button" onClick={handleCreateUser}>
                Salvar
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Toast.Root
          open={toastOpen}
          onOpenChange={setToastOpen}
          duration={5000}
          className={`fixed bottom-0 right-0 m-6 rounded-md p-4 shadow-md ${
            toastType === 'success'
              ? 'bg-green-500 text-white'
              : 'bg-red-500 text-white'
          }`}
        >
          <Toast.Description>{toastMessage}</Toast.Description>
          <Toast.Close className="absolute right-1 top-1" />
        </Toast.Root>
        <Toast.Viewport />
      </Toast.Provider>
    </div>
  )
}
