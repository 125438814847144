import { createBrowserRouter } from 'react-router-dom'

import { Home } from './pages/app/home'
import { OwnerControl } from './pages/app/OwnerControl'
import { Property } from './pages/app/Property'
import { PropertyControl } from './pages/app/PropertyControl'
import { PropertyEditControl } from './pages/app/PropertyEditControl'
import { RegionControl } from './pages/app/RegionControl'
import { UserControl } from './pages/app/userControl'
import { SignIn } from './pages/auth/sign-in'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <SignIn />,
  },
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/usuarios',
    element: <UserControl />,
  },
  {
    path: '/regioes',
    element: <RegionControl />,
  },
  {
    path: '/proprietarios',
    element: <OwnerControl />,
  },
  {
    path: '/cadastro-propriedade',
    element: <PropertyControl />,
  },
  {
    path: '/propriedade/:id',
    element: <Property />,
  },
  {
    path: '/editar/propriedade/:id',
    element: <PropertyEditControl />,
  },
])
