import * as Toast from '@radix-ui/react-toast'
import { useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import { Owner } from '../form-property/property-types'
import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import { Textarea } from '../ui/textarea'

interface CreateOwnerDialogProps {
  onOwnerCreate: (owner: Owner) => void
  trigger: React.ReactNode
}

export function CreateOwnerDialog({ onOwnerCreate }: CreateOwnerDialogProps) {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [telephone, setTelephone] = useState('')
  const [cellPhone, setCellPhone] = useState('')
  const [email, setEmail] = useState('')
  const [observation, setObservation] = useState('')
  const [toastOpen, setToastOpen] = useState(false)
  const [toastType, setToastType] = useState<'success' | 'error'>('success')
  const [toastMessage, setToastMessage] = useState('')

  async function handleCreateOwner() {
    const newOwner = {
      data: {
        name,
        telephone,
        cell_phone: cellPhone,
        email,
        observation,
      },
    }

    try {
      const route = import.meta.env.VITE_URL_ENDPOINT
      const response = await fetch(`${route}/api/owners`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newOwner),
      })

      if (!response.ok) {
        const errorData = await response.json()
        const errorMessage =
          errorData?.message || 'Falha ao criar o proprietário'
        throw new Error(errorMessage)
      }

      const newOwnerData = await response.json()
      const createdOwner = newOwnerData.data

      setOpen(false)
      setName('')
      setTelephone('')
      setCellPhone('')
      setEmail('')
      setObservation('')
      onOwnerCreate(createdOwner)
      setToastType('success')
      setToastMessage('Proprietário criado com sucesso!')
      setToastOpen(true)
    } catch (error: unknown) {
      console.error('Failed to create owner:', error)
      setToastOpen(true)
      setToastType('error')
      setToastMessage(
        error instanceof Error && error.message.includes('already exists')
          ? 'Proprietário já existe!'
          : 'Falha ao criar o proprietário!',
      )
    }
  }

  return (
    <div>
      <Toast.Provider swipeDirection="right">
        <Dialog open={open} onOpenChange={setOpen}>
          <DialogTrigger asChild>
            <Button className="mt-2 flex w-full items-center justify-center rounded-md bg-gray-900 p-2 text-white">
              <p>Cadastrar Proprietário</p>
            </Button>
          </DialogTrigger>
          <DialogContent className="rounded-md bg-white p-4 shadow-lg sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle className="text-xl font-bold">
                Criar Proprietário
              </DialogTitle>
              <DialogDescription className="text-sm text-gray-500">
                Preencha as informações e clique em salvar
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Nome
                </Label>
                <Input
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="telephone" className="text-right">
                  Telefone
                </Label>
                <Input
                  id="telephone"
                  value={telephone}
                  onChange={(e) => setTelephone(e.target.value)}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="cellPhone" className="text-right">
                  Celular
                </Label>
                <Input
                  id="cellPhone"
                  value={cellPhone}
                  onChange={(e) => setCellPhone(e.target.value)}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="email" className="text-right">
                  Email
                </Label>
                <Input
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="observation" className="text-right">
                  Observação
                </Label>
                <Textarea
                  id="observation"
                  value={observation}
                  onChange={(e) => setObservation(e.target.value)}
                  className="col-span-3"
                />
              </div>
            </div>
            <DialogFooter>
              <Button type="button" onClick={handleCreateOwner}>
                Salvar
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Toast.Root
          open={toastOpen}
          onOpenChange={setToastOpen}
          duration={5000}
          className={`fixed bottom-0 right-0 m-6 rounded-md p-4 shadow-md ${
            toastType === 'success'
              ? 'bg-green-500 text-white'
              : 'bg-red-500 text-white'
          }`}
        >
          <Toast.Description>{toastMessage}</Toast.Description>
          <Toast.Close className="absolute right-1 top-1" />
        </Toast.Root>
        <Toast.Viewport />
      </Toast.Provider>
    </div>
  )
}
