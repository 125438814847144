import * as Toast from '@radix-ui/react-toast'
import { Home } from 'lucide-react'
import { useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import { Button } from '../ui/button'

export function OwnerProperties() {
  const [openModel, setOpenModel] = useState(false)
  function handleToOpenModel() {
    setOpenModel(true)
  }

  return (
    <div>
      <Toast.Provider swipeDirection="right">
        <Dialog open={openModel} onOpenChange={setOpenModel}>
          <DialogTrigger asChild>
            <Button
              onClick={handleToOpenModel}
              className="rounded bg-gray-100 p-2 hover:bg-gray-200"
            >
              <Home className="text-gray-700" />
            </Button>
          </DialogTrigger>
          <DialogContent className="rounded-md bg-white p-4 shadow-lg sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle className="text-xl font-bold">
                Lista de Propriedades
              </DialogTitle>{' '}
            </DialogHeader>

            <div className="flex items-center justify-center">
              <p>
                Propriedades <b>em construção 🏗️</b>
              </p>
            </div>
          </DialogContent>
        </Dialog>

        <Toast.Close className="absolute right-1 top-1" />
        <Toast.Viewport />
      </Toast.Provider>
    </div>
  )
}
