// CreateBlockDialog.tsx
import { useEffect, useState } from 'react'

import { Block } from '@/components/form-property/property-types'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { useToast } from '@/components/ui/use-toast'

interface CreateBlockDialogProps {
  onBlockCreate: (block: Block) => void
  trigger: React.ReactNode
  existingBlock?: Block
  condominiumId: number
}

export function CreateBlockDialog({
  onBlockCreate,
  trigger,
  existingBlock,
  condominiumId,
}: CreateBlockDialogProps) {
  const [open, setOpen] = useState(false)
  const [name, setName] = useState('')
  const [numberOfFloors, setNumberOfFloors] = useState('')
  const [apartmentsPerFloor, setApartmentsPerFloor] = useState('')
  const [groundOrFloor, setGroundOrFloor] = useState('')
  const [floorNumber, setFloorNumber] = useState('')
  const [constructionPhase, setConstructionPhase] = useState('')
  const { toast } = useToast()
  const route = import.meta.env.VITE_URL_ENDPOINT

  useEffect(() => {
    if (existingBlock && open) {
      const attrs = existingBlock.attributes
      setName(attrs.name || '')
      setNumberOfFloors(attrs.numberOfFloors || '')
      setApartmentsPerFloor(attrs.apartmentsPerFloor || '')
      setGroundOrFloor(attrs.groundOrFloor || '')
      setFloorNumber(attrs.FloorNumber || '')
      setConstructionPhase(attrs.ConstructionPhase || '')
    } else if (!existingBlock && open) {
      setName('')
      setNumberOfFloors('')
      setApartmentsPerFloor('')
      setGroundOrFloor('')
      setFloorNumber('')
      setConstructionPhase('')
    }
  }, [existingBlock, open])

  const handleSaveBlock = async () => {
    const newBlock = {
      data: {
        name,
        numberOfFloors,
        apartmentsPerFloor,
        groundOrFloor,
        FloorNumber: floorNumber,
        ConstructionPhase: constructionPhase,
        condominium: condominiumId,
      },
    }

    try {
      const method = existingBlock ? 'PUT' : 'POST'
      const url = existingBlock
        ? `${route}/api/blocks/${existingBlock.id}`
        : `${route}/api/blocks`
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newBlock),
      })

      if (!response.ok) {
        const errorData = await response.json()
        const errorMessage = errorData?.message || 'Falha ao salvar o bloco'
        throw new Error(errorMessage)
      }

      const newBlockData = await response.json()
      const savedBlock = newBlockData.data

      onBlockCreate(savedBlock)
      toast({
        title: 'Sucesso',
        description: existingBlock
          ? 'Bloco atualizado com sucesso!'
          : 'Bloco criado com sucesso!',
      })
      setOpen(false)
    } catch (error) {
      console.error('Failed to save block:', error)
      toast({
        title: 'Erro',
        description: 'Falha ao salvar o bloco.',
        variant: 'destructive',
      })
    }
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {existingBlock ? 'Editar Bloco' : 'Adicionar Bloco'}
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {/* Nome */}
            <div>
              <Label htmlFor="name">Nome</Label>
              <Input
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            {/* Número de Andares */}
            <div>
              <Label htmlFor="numberOfFloors">Número de Andares</Label>
              <Input
                id="numberOfFloors"
                value={numberOfFloors}
                onChange={(e) => setNumberOfFloors(e.target.value)}
              />
            </div>
            {/* Apartamentos por Andar */}
            <div>
              <Label htmlFor="apartmentsPerFloor">Aptos por Andar</Label>
              <Input
                id="apartmentsPerFloor"
                value={apartmentsPerFloor}
                onChange={(e) => setApartmentsPerFloor(e.target.value)}
              />
            </div>
            {/* Térreo ou Andar */}
            <div>
              <Label htmlFor="groundOrFloor">Térreo ou Andar</Label>
              <Input
                id="groundOrFloor"
                value={groundOrFloor}
                onChange={(e) => setGroundOrFloor(e.target.value)}
              />
            </div>
            {/* Número do Andar */}
            <div>
              <Label htmlFor="floorNumber">Número do Andar</Label>
              <Input
                id="floorNumber"
                value={floorNumber}
                onChange={(e) => setFloorNumber(e.target.value)}
              />
            </div>
            {/* Fase da Construção */}
            <div>
              <Label htmlFor="constructionPhase">Fase da Construção</Label>
              <Input
                id="constructionPhase"
                value={constructionPhase}
                onChange={(e) => setConstructionPhase(e.target.value)}
              />
            </div>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={handleSaveBlock}>
            {existingBlock ? 'Atualizar' : 'Salvar'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
