import * as Toast from '@radix-ui/react-toast'
import { Trash } from 'lucide-react'
import { useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import { RegionEditDialog } from '../region/RegionEditDialog'
import { Button } from '../ui/button'

interface RegionCardProps {
  id: number
  name: string
  observation: string
  onRegionUpdate: () => void
}

export function RegionCard({
  id,
  name,
  observation,
  onRegionUpdate,
}: RegionCardProps) {
  const [toastOpen, setToastOpen] = useState(false)
  const [toastType, setToastType] = useState('success')
  const [toastMessage, setToastMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)

  async function handleDeleteRegion() {
    setLoading(true)
    try {
      const route = import.meta.env.VITE_URL_ENDPOINT
      const response = await fetch(`${route}/api/regions/${id}`, {
        method: 'DELETE',
      })

      if (!response.ok) {
        throw new Error('Failed to delete region')
      }
      onRegionUpdate()
      setToastType('success')
      setToastMessage('Região excluída com sucesso!')
      setToastOpen(true)
    } catch (error) {
      console.error('Failed to delete region:', error)
      setToastOpen(true)
      setToastType('error')
      setToastMessage('Falha ao excluir a região!')
    } finally {
      setLoading(false)
      setOpenConfirm(false)
    }
  }

  return (
    <div className="w-full p-4">
      <Toast.Provider swipeDirection="right">
        <div className="flex items-center justify-between rounded-md bg-gray-100 p-6 shadow-sm">
          <div className="mr-8 flex flex-col">
            <h2 className="text-lg font-bold text-gray-900">{name}</h2>
            <p className="text-sm text-gray-600">{observation}</p>
          </div>
          <div className="flex items-center space-x-2">
            <RegionEditDialog
              id={id}
              currentName={name}
              currentObservation={observation}
              onRegionUpdate={onRegionUpdate}
            />
            <Dialog open={openConfirm} onOpenChange={setOpenConfirm}>
              <DialogTrigger asChild>
                <Button
                  className="rounded bg-gray-100 p-2 hover:bg-gray-200"
                  onClick={() => setOpenConfirm(true)}
                >
                  <Trash className="text-gray-700" />
                </Button>
              </DialogTrigger>
              <DialogContent className="rounded-md bg-white p-4 shadow-lg sm:max-w-[425px]">
                <DialogHeader>
                  <DialogTitle className="text-xl font-bold">
                    Confirmar Exclusão
                  </DialogTitle>
                  <DialogDescription className="text-sm text-gray-500">
                    Tem certeza que deseja excluir esta região? Esta ação não
                    pode ser desfeita.
                  </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                  <Button
                    className="bg-red-600 text-white"
                    onClick={handleDeleteRegion}
                    disabled={loading}
                  >
                    {loading ? 'Excluindo...' : 'Excluir'}
                  </Button>
                  <Button
                    className="bg-gray-300"
                    onClick={() => setOpenConfirm(false)}
                    disabled={loading}
                  >
                    Cancelar
                  </Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        </div>

        <Toast.Root
          open={toastOpen}
          onOpenChange={setToastOpen}
          duration={5000}
          className={`fixed bottom-0 right-0 m-6 rounded-md p-4 shadow-md ${
            toastType === 'success'
              ? 'bg-green-500 text-white'
              : 'bg-red-500 text-white'
          }`}
        >
          <Toast.Description>{toastMessage}</Toast.Description>
          <Toast.Close className="absolute right-1 top-1" />
        </Toast.Root>
        <Toast.Viewport />
      </Toast.Provider>
    </div>
  )
}
