// src/fontAwesome.js

import { library } from '@fortawesome/fontawesome-svg-core'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import {
  faBars,
  faBath,
  faBed,
  faBorderStyle,
  faBriefcase,
  faBuilding,
  faCalendarAlt,
  faCalendarCheck,
  faCar,
  faChartArea,
  faCheck,
  faCheckCircle,
  faCircle,
  faCog,
  faCogs,
  faDollarSign,
  faDownload,
  faHardHat,
  faHashtag,
  faHome,
  faInfo,
  faInfoCircle,
  faList,
  faMapMarkerAlt,
  faMapPin,
  faPhoneAlt,
  faPrint,
  faReceipt,
  faRoad,
  faRulerCombined,
  faShieldAlt,
  faSwimmer,
  faToilet,
  faUmbrellaBeach,
  faUserShield,
  faUserTie,
  faWater,
} from '@fortawesome/free-solid-svg-icons'

// Adicione todos os ícones que você usará aqui
library.add(
  faDollarSign,
  faChartArea,
  faRulerCombined,
  faMapMarkerAlt,
  faBed,
  faBath,
  faToilet,
  faCar,
  faReceipt,
  faBuilding,
  faUmbrellaBeach,
  faWater,
  faCheckCircle,
  faHardHat,
  faUserTie,
  faPhoneAlt,
  faUserShield,
  faCalendarAlt,
  faCalendarCheck,
  faCogs,
  faCog,
  faCircle,
  faBriefcase,
  faBorderStyle,
  faShieldAlt,
  faRoad,
  faHashtag,
  faMapPin,
  faHome,
  faInfoCircle,
  faList,
  faSwimmer,
  faPrint,
  faDownload,
  faBars,
  faCheck,
  faInfo,
  faWhatsapp,
)
