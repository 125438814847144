// src/components/Property.tsx

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import Logo from '@/assets/Logo Branco.png'
import {
  CondominiumAttributes,
  LeisureData,
  PhotoItem,
  PropertyFormData,
} from '@/components/form-property/property-types'

interface StrapiEntity<T> {
  data: {
    id: number
    attributes: T
  } | null
}

type PropertyAttributes = Omit<
  PropertyFormData,
  'photos' | 'leisure' | 'condominiumData'
> & {
  photos?: { data: PhotoItem[] }
  condominium?: StrapiEntity<CondominiumAttributes>
  leisure?: StrapiEntity<LeisureData>
  updatedAt?: string
}

interface PropertyStrapiData {
  id: number
  attributes: PropertyAttributes
}

interface PropertyStrapiResponse {
  data: PropertyStrapiData
  meta?: unknown
}

interface CondominiumResponse {
  data: {
    id: number
    attributes: CondominiumAttributes
  }
}

interface LeisureResponse {
  data: {
    id: number
    attributes: LeisureData
  }
}

// Ícones para os atributos
const attributeIcons: { [key: string]: [IconPrefix, IconName] } = {
  price: ['fas', 'dollar-sign'],
  totalArea: ['fas', 'chart-area'],
  usableArea: ['fas', 'ruler-combined'],
  neighborhood: ['fas', 'map-marker-alt'],
  region: ['fas', 'map-marker-alt'],
  numberOfBedrooms: ['fas', 'bed'],
  numberOfSuites: ['fas', 'bath'],
  socialBathroom: ['fas', 'toilet'],
  garage: ['fas', 'car'],
  iptu: ['fas', 'receipt'],
  condominiumFee: ['fas', 'building'],
  distanceFromBeach: ['fas', 'umbrella-beach'],
  seaView: ['fas', 'water'],
  status: ['fas', 'check-circle'],
  builderName: ['fas', 'hard-hat'],
  developerName: ['fas', 'hard-hat'],
  managerName: ['fas', 'user-tie'],
  managerPhone: ['fas', 'phone-alt'],
  caretakerName: ['fas', 'user-shield'],
  caretakerPhone: ['fas', 'phone-alt'],
  constructionYear: ['fas', 'calendar-alt'],
  deliverySchedule: ['fas', 'calendar-check'],
  numberOfServiceElevators: ['fas', 'cogs'],
  numberOfPassengerElevators: ['fas', 'cog'],
  Name: ['fas', 'building'],
  commercialStatus: ['fas', 'briefcase'],
  corner: ['fas', 'border-style'],
  guardhouse: ['fas', 'shield-alt'],
  street: ['fas', 'road'],
  neighborhood_con: ['fas', 'map-marker-alt'],
  number: ['fas', 'hashtag'],
  zipCode: ['fas', 'map-pin'],
  commonArea: ['fas', 'layer-group'],
  privateArea: ['fas', 'layer-group'],
  barter: ['fas', 'exchange-alt'],
  financing: ['fas', 'hand-holding-usd'],
  paymentMethod: ['fas', 'credit-card'],
  halfBath: ['fas', 'restroom'],
  bathrooms: ['fas', 'bath'],
  closet: ['fas', 'door-closed'],
  livingRoom: ['fas', 'couch'],
  kitchen: ['fas', 'utensils'],
  laundryRoom: ['fas', 'soap'],
  laundryArea: ['fas', 'soap'],
  pantry: ['fas', 'bread-slice'],
  maidsQuarters: ['fas', 'user-secret'],
  intercom: ['fas', 'phone-volume'],
  elevator: ['fas', 'arrow-up'],
  furnished: ['fas', 'chair'],
  furnitureNotes: ['fas', 'sticky-note'],
  yearOfConstruction: ['fas', 'calendar'],
  keys: ['fas', 'key'],
  cabinets: ['fas', 'box-open'],
  conservation: ['fas', 'tools'],
  copa: ['fas', 'coffee'],
  classification: ['fas', 'layer-group'],
  front: ['fas', 'arrows-alt-h'],
  back: ['fas', 'arrows-alt-h'],
  rightSide: ['fas', 'arrows-alt-h'],
  leftSide: ['fas', 'arrows-alt-h'],
  leveled: ['fas', 'check'],
  fenced: ['fas', 'align-justify'],
  waterSupply: ['fas', 'tint'],
  electricitySupply: ['fas', 'bolt'],
  type: ['fas', 'house-user'],
  numberOfRooms: ['fas', 'door-open'],
  apartmentNumber: ['fas', 'door-open'],
}

function SimpleHeader() {
  return (
    <header className="no-print fixed left-0 right-0 top-0 z-50 flex h-16 items-center justify-center bg-green-800 px-4 shadow-md">
      <div className="flex items-center justify-center space-x-4">
        <Link to="/home">
          <img src={Logo} alt="logo" className="h-11 w-auto" />
        </Link>
      </div>
    </header>
  )
}

// Formata valores booleanos como "Sim"
function formatValue(value: unknown): string {
  if (typeof value === 'boolean') {
    return value ? 'Sim' : ''
  }
  if (typeof value === 'number') {
    return value.toLocaleString('pt-BR')
  }
  return String(value)
}

function renderAttributes(
  attributes: Record<string, unknown>,
  labels: Record<string, string>,
) {
  const filledAttributes = Object.entries(attributes).filter(
    ([, v]) => v !== null && v !== undefined && v !== '' && v !== false,
  )

  if (filledAttributes.length === 0) return null

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
      {filledAttributes.map(([key, value]) => {
        const label = labels[key] || key
        const icon = attributeIcons[key] || ['fas', 'circle']
        const val = formatValue(value)
        if (!val) return null
        return (
          <div key={key} className="flex items-center space-x-3 break-words">
            <FontAwesomeIcon icon={icon} className="text-lg" />
            <div>
              <span className="font-semibold text-gray-800">{label}:</span>{' '}
              <span className="text-gray-700">{val}</span>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export function Property() {
  const { id } = useParams<{ id: string }>()
  const [property, setProperty] = useState<PropertyStrapiData | null>(null)
  const [condominiumData, setCondominiumData] =
    useState<CondominiumAttributes | null>(null)
  const [leisureData, setLeisureData] = useState<LeisureData | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)

  const userCollaborator = localStorage.getItem('userCollaborator')
  const isAdmin = userCollaborator === 'ADMIN'

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const route = import.meta.env.VITE_URL_ENDPOINT
        const res = await fetch(
          `${route}/api/properties/${id}?populate=condominium,leisure,photos`,
        )
        if (!res.ok) throw new Error('Falha ao carregar')
        const data: PropertyStrapiResponse = await res.json()
        setProperty(data.data)

        const condoId = data.data.attributes.condominium?.data?.id
        const leisureId = data.data.attributes.leisure?.data?.id

        if (condoId) {
          const condoRes = await fetch(`${route}/api/condominiums/${condoId}`)
          if (condoRes.ok) {
            const cData: CondominiumResponse = await condoRes.json()
            setCondominiumData(cData.data.attributes)
          }
        }

        if (leisureId) {
          const leisureRes = await fetch(`${route}/api/leisures/${leisureId}`)
          if (leisureRes.ok) {
            const lData: LeisureResponse = await leisureRes.json()
            setLeisureData(lData.data.attributes)
          }
        }
      } catch (err) {
        setError('Erro ao carregar imóvel')
      } finally {
        setLoading(false)
      }
    }

    fetchProperty()
  }, [id])

  if (loading) {
    return (
      <>
        <SimpleHeader />
        <div className="mt-24 text-center text-gray-700">Carregando...</div>
      </>
    )
  }

  if (error) {
    return (
      <>
        <SimpleHeader />
        <div className="mt-24 text-center text-red-500">{error}</div>
      </>
    )
  }

  if (!property) {
    return (
      <>
        <SimpleHeader />
        <div className="mt-24 text-center text-gray-700">
          Imóvel não encontrado.
        </div>
      </>
    )
  }

  const attributes = property.attributes
  const route = import.meta.env.VITE_URL_ENDPOINT
  const photos =
    attributes.photos?.data.map((p) => route + p.attributes.url) || []

  const ultimaAtualizacao = attributes.updatedAt
    ? new Date(attributes.updatedAt).toLocaleDateString('pt-BR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
    : 'Não disponível'

  const buildGoogleMapsUrl = (address: {
    street?: string
    number?: string
    neighborhood?: string
    region?: string
  }) => {
    const query = encodeURIComponent(
      `${address.street || ''}, ${address.number || ''}, ${address.neighborhood || ''}, ${address.region || ''}`,
    )
    return `https://www.google.com/maps/search/?api=1&query=${query}`
  }

  const handlePrint = () => {
    window.print()
  }

  // Função para baixar imagens como .zip
  const handleDownloadImages = async () => {
    const zip = new JSZip()
    const imgFolder = zip.folder('images')
    for (let i = 0; i < photos.length; i++) {
      const imageUrl = photos[i]
      const response = await fetch(imageUrl)
      const blob = await response.blob()
      const extension = imageUrl.substring(imageUrl.lastIndexOf('.')) || '.jpg'
      const filename = `image${i + 1}${extension}`
      imgFolder?.file(filename, blob)
    }
    const content = await zip.generateAsync({ type: 'blob' })
    saveAs(content, `imagens-imovel-${id}.zip`)
  }

  const printStyles = `
  @media print {
    .no-print {
      display: none !important;
    }
    header.no-print {
      display: none !important;
    }
    .swiper, .swiper-button-next, .swiper-button-prev, .swiper-pagination, .swiper-scrollbar {
      display: none !important;
    }
    .print-thumbnails {
      display: flex !important;
      flex-wrap: wrap;
      gap: 4px;
      justify-content: center;
      margin-bottom: 20px;
    }
    .print-thumbnails img {
      width: 80px !important;
      height: auto !important;
      object-fit: cover;
    }
  }
  `

  const labels = {
    totalArea: 'Área Total (m²)',
    usableArea: 'Área Útil (m²)',
    price: 'Preço (R$)',
    neighborhood: 'Bairro',
    region: 'Região',
    numberOfBedrooms: 'Quartos',
    numberOfSuites: 'Suítes',
    socialBathroom: 'Banheiros Sociais',
    garage: 'Vagas de Garagem',
    iptu: 'IPTU (R$)',
    condominiumFee: 'Condomínio (R$)',
    distanceFromBeach: 'Distância da Praia (m)',
    seaView: 'Vista para o Mar',
    status: 'Status do Imóvel',
    commonArea: 'Área Comum (m²)',
    privateArea: 'Área Privativa (m²)',
    barter: 'Aceita Permuta',
    financing: 'Financiamento',
    paymentMethod: 'Método de Pagamento',
    halfBath: 'Lavabo',
    bathrooms: 'Banheiros (Total)',
    closet: 'Closet',
    livingRoom: 'Sala de Estar',
    kitchen: 'Cozinha',
    laundryRoom: 'Lavanderia',
    laundryArea: 'Área de Serviço',
    pantry: 'Despensa',
    maidsQuarters: 'Quarto de Empregada',
    intercom: 'Interfone',
    elevator: 'Elevador',
    furnished: 'Mobiliado',
    furnitureNotes: 'Observações Mobiliário',
    yearOfConstruction: 'Ano de Construção',
    keys: 'Chaves',
    cabinets: 'Armários',
    conservation: 'Conservação',
    guardhouse: 'Guarita no Imóvel',
    copa: 'Copa',
    classification: 'Classificação',
    front: 'Frente',
    back: 'Fundo',
    rightSide: 'Lado Direito',
    leftSide: 'Lado Esquerdo',
    corner: 'Esquina',
    leveled: 'Nivelado',
    fenced: 'Cercado',
    waterSupply: 'Fornecimento de Água',
    electricitySupply: 'Fornecimento de Eletricidade',
    type: 'Tipo do Imóvel',
    numberOfRooms: 'Número de Cômodos',
    apartmentNumber: 'Número do Apartamento',
  }

  const condominiumLabels = {
    builderName: 'Construtora',
    developerName: 'Incorporadora',
    managerName: 'Síndico',
    managerPhone: 'Telefone do Síndico',
    caretakerName: 'Zelador',
    caretakerPhone: 'Telefone do Zelador',
    constructionYear: 'Ano de Construção',
    deliverySchedule: 'Prazo de Entrega',
    numberOfServiceElevators: 'Nº Elevadores de Serviço',
    numberOfPassengerElevators: 'Nº Elevadores Sociais',
    Name: 'Nome do Condomínio',
    commercialStatus: 'Status Comercial',
    corner: 'Esquina',
    guardhouse: 'Guarita',
    region: 'Região',
    street: 'Rua',
    neighborhood: 'Bairro',
    number: 'Número',
    zipCode: 'CEP',
  }

  const leisureLabels: { [key: string]: string } = {
    partyHall: 'Salão de Festas',
    adultPool: 'Piscina Adulto',
    kidsPool: 'Piscina Infantil',
    gym: 'Academia',
    sauna: 'Sauna',
    playground: 'Playground',
    sportsCourt: 'Quadra Esportiva',
    tennisCourt: 'Quadra de Tênis',
    bbqArea: 'Churrasqueira',
    cinemaRoom: 'Cinema',
    socialHall: 'Salão Social',
    gameRoom: 'Sala de Jogos',
    squashCourt: 'Quadra de Squash',
    wetDeckPool: 'Piscina com Deck Molhado',
    lapPool: 'Piscina de Raia',
    kidsBeachPool: 'Piscina Infantil com Praia',
    solarium: 'Solarium',
    poolBar: 'Bar na Piscina',
    massageRoom: 'Sala de Massagem',
    womenSpace: 'Espaço Mulher',
    petPlace: 'Pet Place',
    youthGameRoom: 'Sala de Jogos Juvenil',
    toyLibrary: 'Brinquedoteca',
    pilatesSpace: 'Espaço Pilates',
    lounge: 'Lounge',
    gourmetSpace: 'Espaço Gourmet',
    kidsPartyRoom: 'Salão de Festas Infantil',
    adultPartyRoomWithBar: 'Salão de Festas Adulto com Bar',
    adultGameRoom: 'Sala de Jogos Adulto',
    readingRoom: 'Sala de Leitura',
    hammockArea: 'Área de Descanso (Redário)',
    zenSpace: 'Espaço Zen',
    coworkingSpace: 'Espaço Coworking',
    adultTVRoom: 'Sala de TV Adulto',
    gourmetBar: 'Espaço Gourmet com Bar',
  }

  let filteredLeisure: { [key: string]: unknown } = {}
  if (leisureData) {
    const leisureWithoutProperty = { ...leisureData } as Record<string, unknown>
    delete leisureWithoutProperty.property
    filteredLeisure = Object.fromEntries(
      Object.entries(leisureWithoutProperty).filter(([, v]) => v === true),
    )
  }

  const filteredCondoData = Object.fromEntries(
    Object.entries(condominiumData ?? {}).filter(
      ([key]) =>
        key !== 'createdAt' && key !== 'updatedAt' && key !== 'publishedAt',
    ),
  )

  // Abaixo manteremos exatamente as mesmas lógicas de agrupar campos e exibir seções
  // conforme o código anterior. Nenhum campo é removido, todos são mostrados.

  // Campos já exibidos
  const shownFields = new Set([
    'price',
    'totalArea',
    'status',
    'iptu',
    'condominiumFee',
    'numberOfBedrooms',
    'numberOfSuites',
    'socialBathroom',
    'garage',
    'usableArea',
    'seaView',
    'distanceFromBeach',
    'commonArea',
    'privateArea',
    'street',
    'number',
    'neighborhood',
    'region',
    'updatedAt',
    'owner',
    'photos',
    'leisure',
    'condominium',
    'block',
    'propertyType',
  ])

  // Verificar campos restantes
  const allPropertyKeys = Object.keys(attributes)
  const otherFields: Record<string, unknown> = {}
  for (const key of allPropertyKeys) {
    if (!shownFields.has(key)) {
      otherFields[key] = attributes[key as keyof PropertyAttributes]
    }
  }

  const commercialFields: Record<string, unknown> = {}
  const roomFields: Record<string, unknown> = {}
  const structureFields: Record<string, unknown> = {}
  const areaFields: Record<string, unknown> = {}

  // Campos comerciais
  for (const f of ['barter', 'financing', 'paymentMethod']) {
    if (f in otherFields) {
      commercialFields[f] = otherFields[f]
      delete otherFields[f]
    }
  }

  // Ambientes e cômodos
  for (const f of [
    'numberOfRooms',
    'apartmentNumber',
    'halfBath',
    'bathrooms',
    'closet',
    'livingRoom',
    'kitchen',
    'laundryRoom',
    'laundryArea',
    'pantry',
    'maidsQuarters',
  ]) {
    if (f in otherFields) {
      roomFields[f] = otherFields[f]
      delete otherFields[f]
    }
  }

  // Detalhes da estrutura
  for (const f of [
    'yearOfConstruction',
    'keys',
    'cabinets',
    'conservation',
    'guardhouse',
    'copa',
    'intercom',
    'elevator',
    'furnished',
    'furnitureNotes',
  ]) {
    if (f in otherFields) {
      structureFields[f] = otherFields[f]
      delete otherFields[f]
    }
  }

  // Área, se for propertyType === 'area'
  if (attributes.propertyType === 'area') {
    for (const f of [
      'classification',
      'type',
      'front',
      'back',
      'rightSide',
      'leftSide',
      'corner',
      'leveled',
      'fenced',
      'waterSupply',
      'electricitySupply',
    ]) {
      if (f in otherFields) {
        areaFields[f] = otherFields[f]
        delete otherFields[f]
      }
    }
  }

  return (
    <>
      <style>{printStyles}</style>

      {/* Thumbnails para impressão */}
      {photos.length > 0 && (
        <div className="print-thumbnails hidden">
          {photos.map((url, idx) => (
            <img
              key={idx}
              src={url}
              alt={`Foto ${idx + 1}`}
              className="h-auto w-24 object-cover"
            />
          ))}
        </div>
      )}

      <SimpleHeader />
      <div className="min-h-screen bg-gray-50 pt-20 font-sans">
        <div className="no-print relative w-full overflow-hidden">
          {photos.length > 0 ? (
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={10}
              slidesPerView={2}
              navigation
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              className="w-full"
              breakpoints={{
                640: { slidesPerView: 2, spaceBetween: 10 },
                768: { slidesPerView: 2, spaceBetween: 15 },
                1024: { slidesPerView: 3, spaceBetween: 20 },
              }}
            >
              {photos.map((url, idx) => (
                <SwiperSlide key={idx}>
                  <div className="relative h-64 w-full sm:h-80 md:h-96">
                    <img
                      src={url}
                      alt={`Foto ${idx + 1}`}
                      className="h-full w-full object-cover"
                      loading="lazy"
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className="flex h-64 items-center justify-center bg-gray-200 text-gray-500">
              Sem imagem disponível
            </div>
          )}
        </div>
        <div className="container mx-auto px-4 pt-6">
          <div className="mb-6 flex flex-col items-start justify-between gap-4 border-b pb-4 sm:flex-row sm:items-center">
            <h1 className="flex items-center gap-2 text-3xl font-bold text-gray-800">
              <FontAwesomeIcon
                icon={['fas', 'home']}
                className="text-green-700"
              />
              Imóvel #{property.id}
            </h1>
            <div className="no-print flex flex-wrap gap-2">
              <button
                onClick={() => {
                  const mapsUrl = buildGoogleMapsUrl({
                    street: attributes.street,
                    number: attributes.number,
                    neighborhood: attributes.neighborhood,
                    region: attributes.region,
                  })
                  window.open(mapsUrl, '_blank')
                }}
                className="flex items-center gap-2 rounded bg-red-800 px-4 py-2 text-sm text-white transition hover:bg-red-900"
              >
                <FontAwesomeIcon icon={['fas', 'map-marker-alt']} />
                Ver no Google Maps
              </button>
              <button
                onClick={handlePrint}
                className="flex items-center gap-2 rounded bg-blue-600 px-4 py-2 text-sm text-white transition hover:bg-blue-700"
              >
                <FontAwesomeIcon icon={['fas', 'print']} />
                Imprimir
              </button>

              {photos.length > 0 && (
                <button
                  onClick={handleDownloadImages}
                  className="flex items-center gap-2 rounded bg-gray-600 px-4 py-2 text-sm text-white transition hover:bg-gray-700"
                >
                  <FontAwesomeIcon icon={['fas', 'download']} />
                  Baixar Imagens (.zip)
                </button>
              )}
              {isAdmin && (
                <button className="flex items-center gap-2 rounded bg-green-800 px-4 py-2 text-sm text-white transition hover:bg-green-900">
                  <Link to={`/editar/propriedade/${id}`}>
                    Editar propriedade
                  </Link>
                </button>
              )}
            </div>
          </div>

          <p className="mb-2 text-sm text-gray-500">
            Última atualização: {ultimaAtualizacao}
          </p>

          <p className="mb-6 flex items-center gap-1 text-gray-600">
            <FontAwesomeIcon
              icon={['fas', 'map-marker-alt']}
              className="text-red-600"
            />
            {`${attributes.street}, ${attributes.number} - ${attributes.neighborhood}, ${attributes.region}`}
          </p>

          {/* Características do Imóvel */}
          <div className="mt-8 border-t pt-6">
            <h2 className="mb-4 flex items-center gap-2 text-xl font-semibold text-gray-800">
              <FontAwesomeIcon
                icon={['fas', 'list']}
                className="text-yellow-500"
              />
              Características do Imóvel
            </h2>
            {renderAttributes(
              {
                distanceFromBeach: attributes.distanceFromBeach,
                commonArea: attributes.commonArea,
                privateArea: attributes.privateArea,
              },
              labels,
            ) || (
              <p className="text-gray-500">Nenhuma característica adicional.</p>
            )}
          </div>

          {/* Caso haja campos comerciais */}
          {renderAttributes(commercialFields, labels) && (
            <div className="mt-8 border-t pt-6">
              <h2 className="mb-4 flex items-center gap-2 text-xl font-semibold text-gray-800">
                <FontAwesomeIcon
                  icon={['fas', 'hand-holding-usd']}
                  className="text-green-600"
                />
                Condições Comerciais
              </h2>
              {renderAttributes(commercialFields, labels)}
            </div>
          )}

          {/* Ambientes e Cômodos */}
          {renderAttributes(roomFields, labels) && (
            <div className="mt-8 border-t pt-6">
              <h2 className="mb-4 flex items-center gap-2 text-xl font-semibold text-gray-800">
                <FontAwesomeIcon
                  icon={['fas', 'couch']}
                  className="text-purple-500"
                />
                Ambientes e Cômodos
              </h2>
              {renderAttributes(roomFields, labels)}
            </div>
          )}

          {/* Detalhes da Estrutura */}
          {renderAttributes(structureFields, labels) && (
            <div className="mt-8 border-t pt-6">
              <h2 className="mb-4 flex items-center gap-2 text-xl font-semibold text-gray-800">
                <FontAwesomeIcon
                  icon={['fas', 'tools']}
                  className="text-gray-700"
                />
                Detalhes da Estrutura
              </h2>
              {renderAttributes(structureFields, labels)}
            </div>
          )}

          {/* Características da Área */}
          {attributes.propertyType === 'area' &&
            renderAttributes(areaFields, labels) && (
              <div className="mt-8 border-t pt-6">
                <h2 className="mb-4 flex items-center gap-2 text-xl font-semibold text-gray-800">
                  <FontAwesomeIcon
                    icon={['fas', 'layer-group']}
                    className="text-blue-500"
                  />
                  Características da Área
                </h2>
                {renderAttributes(areaFields, labels)}
              </div>
            )}

          {/* Detalhes do Condomínio */}
          {condominiumData &&
            Object.values(filteredCondoData).some(
              (val) => val !== null && val !== undefined && val !== '',
            ) && (
              <div className="mt-8 border-t pt-6">
                <h2 className="mb-4 flex items-center gap-2 text-xl font-semibold text-gray-800">
                  <FontAwesomeIcon
                    icon={['fas', 'building']}
                    className="text-indigo-500"
                  />
                  Detalhes do Condomínio
                </h2>
                {renderAttributes(
                  filteredCondoData as Record<string, unknown>,
                  condominiumLabels,
                ) || (
                  <p className="text-gray-500">
                    Nenhum detalhe de condomínio disponível.
                  </p>
                )}
              </div>
            )}

          {/* Lazer */}
          {leisureData && Object.keys(filteredLeisure).length > 0 && (
            <div className="mt-8 border-t pt-6">
              <h2 className="mb-4 flex items-center gap-2 text-xl font-semibold text-gray-800">
                <FontAwesomeIcon
                  icon={['fas', 'swimmer']}
                  className="text-teal-500"
                />
                Lazer
              </h2>
              <ul className="list-disc space-y-2 pl-5 text-gray-700">
                {Object.keys(filteredLeisure).map((itemKey) => (
                  <li key={itemKey} className="flex items-center">
                    <FontAwesomeIcon
                      icon={['fas', 'check']}
                      className="mr-2 text-green-500"
                    />
                    {leisureLabels[itemKey] || itemKey}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Observações */}
          {(attributes.internalObservation ||
            attributes.externalObservation) && (
            <div className="mt-8 border-t pt-6">
              <h2 className="mb-4 flex items-center gap-2 text-xl font-semibold text-gray-800">
                <FontAwesomeIcon
                  icon={['fas', 'info']}
                  className="text-green-500"
                />
                Observações
              </h2>
              {attributes.internalObservation && (
                <div className="mb-4">
                  <h3 className="mb-2 text-lg font-semibold text-gray-700">
                    Observações Internas
                  </h3>
                  <p className="whitespace-pre-line text-gray-700">
                    {attributes.internalObservation}
                  </p>
                </div>
              )}
              {attributes.externalObservation && (
                <div>
                  <h3 className="mb-2 text-lg font-semibold text-gray-700">
                    Observações Externas
                  </h3>
                  <p className="mb-24 whitespace-pre-line text-gray-700">
                    {attributes.externalObservation}
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}
