// Home.tsx

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'

import { saveAs } from 'file-saver'
import { useEffect, useState } from 'react'
import * as XLSX from 'xlsx'

import type {
  Attribute,
  BlockAttributes,
  CondominiumAttributes,
  LeisureData,
  PhotoItem,
  PropertyFormData,
} from '@/components/form-property/property-types'
import { Header } from '@/components/Header'
import { PropertyCard } from '@/components/PropertyCard'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

interface Property {
  id: number
  attributes: PropertyFormData
}

export function Home() {
  const [propertyType, setPropertyType] = useState<string>('')
  const [priceRange, setPriceRange] = useState<string>('')
  const [properties, setProperties] = useState<Property[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPages, setTotalPages] = useState<number>(0)
  const itemsPerPage = 10
  const [status, setStatus] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const route = import.meta.env.VITE_URL_ENDPOINT

  const buildFilters = () => {
    let filters = ''

    if (propertyType) {
      filters += `&filters[propertyType][$eq]=${encodeURIComponent(propertyType)}`
    }

    if (status) {
      filters += `&filters[status][$eq]=${encodeURIComponent(status)}`
    }

    if (priceRange) {
      const [minPrice, maxPrice] = priceRange.split('~')
      if (minPrice) {
        filters += `&filters[price][$gte]=${encodeURIComponent(minPrice)}`
      }
      if (maxPrice) {
        filters += `&filters[price][$lte]=${encodeURIComponent(maxPrice)}`
      }
    }

    if (type) {
      filters += `&filters[type][$eqi]=${encodeURIComponent(type)}`
    }

    if (searchQuery) {
      filters += `&filters[$or][0][street][$containsi]=${encodeURIComponent(
        searchQuery,
      )}`
      filters += `&filters[$or][1][neighborhood][$containsi]=${encodeURIComponent(
        searchQuery,
      )}`
      filters += `&filters[$or][2][region][$containsi]=${encodeURIComponent(
        searchQuery,
      )}`
    }

    return filters
  }

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        setLoading(true)
        const filters = buildFilters()
        const requestUrl = `${route}/api/properties?pagination[page]=${currentPage}&pagination[pageSize]=${itemsPerPage}&populate=*&${filters}`
        const response = await fetch(requestUrl)
        const data = await response.json()
        setProperties(data.data || [])
        setTotalPages(data.meta.pagination.pageCount || 1)
      } catch (error) {
        console.error('Erro ao buscar propriedades:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchProperties()
  }, [currentPage, propertyType, priceRange, status, type, searchQuery, route])

  function formatCurrency(value: string | number | null | undefined): string {
    if (!value) return 'R$ 0,00'
    const numberValue =
      typeof value === 'string' ? parseFloat(value.replace(/\D/g, '')) : value
    if (isNaN(numberValue)) return 'N/A'
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(numberValue)
  }

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleSearch = () => {
    setCurrentPage(1)
  }

  const booleanToSimNao = (val: boolean | null | undefined) => {
    if (val === null || val === undefined) return ''
    return val ? 'Sim' : 'Não'
  }

  // Função auxiliar para extrair attributes do condomínio
  function getCondoAttributes(prop: PropertyFormData): CondominiumAttributes {
    const c = prop.condominium as unknown as {
      data?: { attributes?: CondominiumAttributes }
    }
    return c?.data?.attributes || ({} as CondominiumAttributes)
  }

  // Função auxiliar para extrair attributes do bloco
  function getBlockAttributes(prop: PropertyFormData): BlockAttributes {
    const b = prop.block as unknown as {
      data?: { attributes?: BlockAttributes }
    }
    return b?.data?.attributes || ({} as BlockAttributes)
  }

  // Função auxiliar para extrair attributes do lazer
  function getLeisureAttributes(prop: PropertyFormData): LeisureData {
    // Ajuste aqui: a API retorna leisure como { data: { attributes: {...} } }
    const l = prop.leisure as unknown as {
      data?: { attributes?: LeisureData }
    }

    if (l?.data?.attributes) {
      return l.data.attributes
    }

    // Se não tiver data, retornamos um objeto LeisureData padrão com todos false
    return {
      id: 0,
      partyHall: false,
      adultPool: false,
      gameRoom: false,
      kidsPool: false,
      gym: false,
      playground: false,
      sportsCourt: false,
      sauna: false,
      tennisCourt: false,
      bbqArea: false,
      squashCourt: false,
      socialHall: false,
      wetDeckPool: false,
      lapPool: false,
      kidsBeachPool: false,
      solarium: false,
      poolBar: false,
      massageRoom: false,
      womenSpace: false,
      petPlace: false,
      youthGameRoom: false,
      toyLibrary: false,
      pilatesSpace: false,
      lounge: false,
      gourmetSpace: false,
      kidsPartyRoom: false,
      adultPartyRoomWithBar: false,
      adultGameRoom: false,
      readingRoom: false,
      hammockArea: false,
      zenSpace: false,
      coworkingSpace: false,
      adultTVRoom: false,
      gourmetBar: false,
      cinemaRoom: false,
      property: { data: null },
    }
  }

  const handleExport = async () => {
    try {
      setLoading(true)
      const filters = buildFilters()
      // Buscar todas as propriedades sem paginação
      const requestUrl = `${route}/api/properties?populate=condominium,block,leisure,photos&${filters}`
      const response = await fetch(requestUrl)
      const data = await response.json()
      const props: Property[] = data.data || []

      const rows: Record<string, string>[] = []

      for (const p of props) {
        const prop = p.attributes
        const condo = getCondoAttributes(prop)
        const block = getBlockAttributes(prop)
        const leisure = getLeisureAttributes(prop)

        const row = {
          ID: String(p.id),
          TipoPropriedade: prop.propertyType || '',
          Tipo: prop.type || '',
          Status: prop.status || '',
          Rua: prop.street || '',
          Número: prop.number || '',
          Bairro: prop.neighborhood || '',
          Região: prop.region || '',
          CEP: prop.zipCode || '',
          ÁreaTotal: prop.totalArea || '',
          ÁreaÚtil: prop.usableArea || '',
          ÁreaComum: prop.commonArea || '',
          ÁreaPrivativa: prop.privateArea || '',
          DistanciaPraia: prop.distanceFromBeach || '',
          Preço: prop.price || '',
          Condomínio: prop.condominiumFee || '',
          IPTU: prop.iptu || '',
          AceitaPermuta: booleanToSimNao(prop.barter),
          Financiamento: booleanToSimNao(prop.financing),
          MétodoPagamento: prop.paymentMethod || '',
          VistaMar: booleanToSimNao(prop.seaView),
          ObservacaoInterna: prop.internalObservation || '',
          ObservacaoExterna: prop.externalObservation || '',
          // Condomínio
          CondNome: condo.Name || '',
          CondRegião: condo.region || '',
          CondRua: condo.street || '',
          CondBairro: condo.neighborhood || '',
          CondNúmero: condo.number || '',
          CondCEP: condo.zipCode || '',
          CondStatusComercial: condo.commercialStatus || '',
          CondConstrutora: condo.builderName || '',
          CondIncorporadora: condo.developerName || '',
          CondSíndico: condo.managerName || '',
          CondTelSíndico: condo.managerPhone || '',
          CondZelador: condo.caretakerName || '',
          CondTelZelador: condo.caretakerPhone || '',
          CondAnoConstrucao: condo.constructionYear || '',
          CondNroElevServ: condo.numberOfServiceElevators || '',
          CondNroElevSocial: condo.numberOfPassengerElevators || '',
          CondPrazoEntrega: condo.deliverySchedule || '',
          CondEsquina: booleanToSimNao(condo.corner),
          CondGuarita: booleanToSimNao(condo.guardhouse),
          CondInterfone: booleanToSimNao(condo.intercom),
          CondTerraco: booleanToSimNao(condo.terrace),
          CondGaragemSubsolo: booleanToSimNao(condo.undergroundGarage),
          CondServicoPraia: booleanToSimNao(condo.beachService),
          CondGuardaEquipPraia: booleanToSimNao(condo.beachEquipmentStorage),
          CondPortaoEletronico: booleanToSimNao(condo.electronicGate),
          CondGaragemTerrea: booleanToSimNao(condo.groundFloorGarage),
          CondGasColetivo: booleanToSimNao(condo.collectiveGas),
          // Bloco
          BlocoNome: block.name || '',
          BlocoNroAndares: block.numberOfFloors || '',
          BlocoAptosPorAndar: block.apartmentsPerFloor || '',
          BlocoTérreoOuAndar: block.groundOrFloor || '',
          BlocoAndarNumero: block.FloorNumber || '',
          BlocoFaseConstrucao: block.ConstructionPhase || '',
          // Lazer
          LazerSalaoFestas: booleanToSimNao(leisure.partyHall),
          LazerPiscinaAdulto: booleanToSimNao(leisure.adultPool),
          LazerSalaJogos: booleanToSimNao(leisure.gameRoom),
          LazerPiscinaInfantil: booleanToSimNao(leisure.kidsPool),
          LazerAcademia: booleanToSimNao(leisure.gym),
          LazerPlayground: booleanToSimNao(leisure.playground),
          LazerQuadraEsportiva: booleanToSimNao(leisure.sportsCourt),
          LazerSauna: booleanToSimNao(leisure.sauna),
          LazerQuadraTenis: booleanToSimNao(leisure.tennisCourt),
          LazerChurrasqueira: booleanToSimNao(leisure.bbqArea),
          LazerQuadraSquash: booleanToSimNao(leisure.squashCourt),
          LazerSalaoSocial: booleanToSimNao(leisure.socialHall),
          LazerPiscinaDeckMolhado: booleanToSimNao(leisure.wetDeckPool),
          LazerPiscinaRaia: booleanToSimNao(leisure.lapPool),
          LazerPiscinaInfPraia: booleanToSimNao(leisure.kidsBeachPool),
          LazerSolarium: booleanToSimNao(leisure.solarium),
          LazerBarPiscina: booleanToSimNao(leisure.poolBar),
          LazerSalaMassagem: booleanToSimNao(leisure.massageRoom),
          LazerEspacoMulher: booleanToSimNao(leisure.womenSpace),
          LazerPetPlace: booleanToSimNao(leisure.petPlace),
          LazerSalaJogosJuvenil: booleanToSimNao(leisure.youthGameRoom),
          LazerBrinquedoteca: booleanToSimNao(leisure.toyLibrary),
          LazerEspacoPilates: booleanToSimNao(leisure.pilatesSpace),
          LazerLounge: booleanToSimNao(leisure.lounge),
          LazerEspacoGourmet: booleanToSimNao(leisure.gourmetSpace),
          LazerSalaoFestasInfantil: booleanToSimNao(leisure.kidsPartyRoom),
          LazerSalaoFestasAdultoBar: booleanToSimNao(
            leisure.adultPartyRoomWithBar,
          ),
          LazerSalaJogosAdulto: booleanToSimNao(leisure.adultGameRoom),
          LazerSalaLeitura: booleanToSimNao(leisure.readingRoom),
          LazerHammockArea: booleanToSimNao(leisure.hammockArea),
          LazerZenSpace: booleanToSimNao(leisure.zenSpace),
          LazerCoworking: booleanToSimNao(leisure.coworkingSpace),
          LazerSalaTVAdulto: booleanToSimNao(leisure.adultTVRoom),
          LazerGourmetBar: booleanToSimNao(leisure.gourmetBar),
          LazerCinema: booleanToSimNao(leisure.cinemaRoom),
        }

        rows.push(row)
      }

      const worksheet = XLSX.utils.json_to_sheet(rows)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Propriedades')

      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
      const blob = new Blob([wbout], { type: 'application/octet-stream' })
      saveAs(blob, 'propriedades.xlsx')
    } catch (error) {
      console.error('Erro ao exportar propriedades:', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Header
        filterContent={
          <div>
            <div className="flex items-center justify-center gap-5">
              <div>
                <Label htmlFor="status" className="text-black">
                  Status
                </Label>
                <Select onValueChange={(value) => setStatus(value)}>
                  <SelectTrigger
                    id="status"
                    name="status"
                    className="mb-4 mt-1 w-[180px]"
                  >
                    <SelectValue placeholder="Status" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="À venda">À venda</SelectItem>
                    <SelectItem value="Vendido">Vendido</SelectItem>
                    <SelectItem value="Suspenso">Suspenso</SelectItem>
                    <SelectItem value="Locação">Locação</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div>
                <Label className="text-black">Tipo de Propriedade</Label>
                <Select onValueChange={(value) => setPropertyType(value)}>
                  <SelectTrigger
                    id="propertyType"
                    name="propertyType"
                    className="mb-4 mt-1 w-[180px]"
                  >
                    <SelectValue placeholder="Selecione o tipo de propriedade" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="apartment">Apartamento</SelectItem>
                    <SelectItem value="house">Casa</SelectItem>
                    <SelectItem value="commercial_property">
                      Área Comercial
                    </SelectItem>
                    <SelectItem value="area">Área</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              {propertyType === '' ? (
                <div>
                  <Label htmlFor="type" className="text-gray-500">
                    Tipo
                  </Label>
                  <Select disabled>
                    <SelectTrigger
                      id="type"
                      name="type"
                      className="mb-4 mt-1 w-[180px]"
                    >
                      <SelectValue placeholder="Selecione o tipo de Propriedade" />
                    </SelectTrigger>
                    <SelectContent></SelectContent>
                  </Select>
                </div>
              ) : null}
              {propertyType === 'apartment' ? (
                <div>
                  <Label htmlFor="type" className="text-black">
                    Tipo
                  </Label>
                  <Select onValueChange={(value) => setType(value)}>
                    <SelectTrigger
                      id="type"
                      name="type"
                      className="mb-4 mt-1 w-[180px]"
                    >
                      <SelectValue placeholder="Selecione o tipo de apartamento" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Apartamento">Apartamento</SelectItem>
                      <SelectItem value="Flat">Flat</SelectItem>
                      <SelectItem value="Cobertura">Cobertura</SelectItem>
                      <SelectItem value="Gardem">Gardem</SelectItem>
                      <SelectItem value="Triplex">Triplex</SelectItem>
                      <SelectItem value="Kitnet">Kitnet</SelectItem>
                      <SelectItem value="Penthouse">Penthouse</SelectItem>
                      <SelectItem value="Studio">Studio</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              ) : null}
              {propertyType === 'house' ? (
                <div>
                  <Label htmlFor="type" className="text-black">
                    Tipo
                  </Label>
                  <Select onValueChange={(value) => setType(value)}>
                    <SelectTrigger
                      id="type"
                      name="type"
                      className="mb-4 mt-1 w-[180px]"
                    >
                      <SelectValue placeholder="Selecione o tipo de casa" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Casa">Casa</SelectItem>
                      <SelectItem value="Sobrado">Sobrado</SelectItem>
                      <SelectItem value="Duplex">Duplex</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              ) : null}
              {propertyType === 'commercial_property' ? (
                <div>
                  <Label htmlFor="type" className="text-black">
                    Tipo
                  </Label>
                  <Select onValueChange={(value) => setType(value)}>
                    <SelectTrigger
                      id="type"
                      name="type"
                      className="mb-4 mt-1 w-[180px]"
                    >
                      <SelectValue placeholder="Selecione o tipo de Propriedade Comercial" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Pousada">Pousada</SelectItem>
                      <SelectItem value="Conjunto">Conjunto</SelectItem>
                      <SelectItem value="Loja">Loja</SelectItem>
                      <SelectItem value="Prédio">Prédio</SelectItem>
                      <SelectItem value="Sala">Sala</SelectItem>
                      <SelectItem value="Galpão">Galpão</SelectItem>
                      <SelectItem value="Hotel">Hotel</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              ) : null}
              {propertyType === 'area' ? (
                <div>
                  <Label htmlFor="type" className="text-black">
                    Tipo
                  </Label>
                  <Select onValueChange={(value) => setType(value)}>
                    <SelectTrigger
                      id="type"
                      name="type"
                      className="mb-4 mt-1 w-[180px]"
                    >
                      <SelectValue placeholder="Selecione o tipo de Área" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="Residencial">Residencial</SelectItem>
                      <SelectItem value="Comercial">Comercial</SelectItem>
                      <SelectItem value="Industrial">Industrial</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              ) : null}
              <div>
                <Label className="text-black">Preço da venda</Label>
                <Select onValueChange={(value) => setPriceRange(value)}>
                  <SelectTrigger
                    id="price"
                    name="price"
                    className="mb-4 mt-1 w-[180px]"
                  >
                    <SelectValue placeholder="Selecione o preço" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="~400000">Até 400.000 </SelectItem>
                    <SelectItem value="400000~600000">
                      De 400.000 até 600.000
                    </SelectItem>
                    <SelectItem value="600000~800000">
                      De 600.000 até 800.000
                    </SelectItem>
                    <SelectItem value="800000~1000000">
                      De 800.000 até 1.000.000
                    </SelectItem>
                    <SelectItem value="1000000~2000000">
                      De 1.000.000 até 2.000.000
                    </SelectItem>
                    <SelectItem value="2000000~4000000">
                      De 2.000.000 até 4.000.000
                    </SelectItem>
                    <SelectItem value="4000000~6000000">
                      De 4.000.000 até 6.000.000
                    </SelectItem>
                    <SelectItem value="6000000~8000000">
                      De 6.000.000 até 8.000.000
                    </SelectItem>
                    <SelectItem value="8000000~10000000">
                      De 8.000.000 até 10.000.000
                    </SelectItem>
                    <SelectItem value="10000000~20000000">
                      De 10.000.000 até 20.000.000
                    </SelectItem>
                    <SelectItem value="20000000~40000000">
                      De 20.000.000 até 40.000.000
                    </SelectItem>
                    <SelectItem value="40000000~60000000">
                      De 40.000.000 até 60.000.000
                    </SelectItem>
                    <SelectItem value="60000000~">
                      Acima de 60.000.000
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>
            </div>
            <div className="mt-6 flex flex-row items-center justify-center gap-6">
              <input
                type="text"
                placeholder="Buscar imóveis"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="h-[38px] w-[350px] rounded-none border px-3 py-1"
              />
              <button
                type="button"
                onClick={handleSearch}
                className="flex h-[38px] w-[140px] items-center justify-center gap-4 bg-green-800 text-white"
              >
                <i className="fa fa-search text-white-500"></i>
                Pesquisar
              </button>
              {/* Botão para exportar Excel */}
              <button
                type="button"
                onClick={handleExport}
                className="flex h-[38px] w-[180px] items-center justify-center gap-4 bg-blue-600 text-white"
              >
                <i className="fa fa-file-excel text-white-500"></i>
                Exportar Excel
              </button>
            </div>
          </div>
        }
      />

      {loading && (
        <div className="mt-10 text-center text-gray-500">
          Carregando imóveis...
        </div>
      )}

      {!loading && properties && properties.length > 0
        ? properties.map((property) => {
            if (!property || !property.attributes) {
              return null
            }
            const attributes = property.attributes

            const images = (
              (attributes.photos as { data: PhotoItem[] } | undefined)?.data ??
              []
            ).map(
              (photoItem: PhotoItem) => `${route}${photoItem.attributes.url}`,
            )

            let description = ''
            const typeValue = attributes.type || ''
            let propertyAttributes: Attribute[] = []

            if (attributes.propertyType === 'apartment') {
              description = 'Apartamento'
              propertyAttributes = [
                {
                  label: 'Quartos',
                  value: attributes.numberOfBedrooms || 'N/A',
                },
                {
                  label: 'Suítes',
                  value: attributes.numberOfSuites || 'N/A',
                },
                {
                  label: 'Banheiros',
                  value: attributes.socialBathroom || 'N/A',
                },
                {
                  label: 'Vagas',
                  value: attributes.garage || 'N/A',
                },
                {
                  label: 'Área (m²)',
                  value: attributes.totalArea || 'N/A',
                },
              ]
            } else if (attributes.propertyType === 'house') {
              description = 'Casa'
              propertyAttributes = [
                {
                  label: 'Quartos',
                  value: attributes.numberOfBedrooms || 'N/A',
                },
                {
                  label: 'Suítes',
                  value: attributes.numberOfSuites || 'N/A',
                },
                {
                  label: 'Banheiros',
                  value: attributes.socialBathroom || 'N/A',
                },
                {
                  label: 'Vagas',
                  value: attributes.garage || 'N/A',
                },
                {
                  label: 'Área (m²)',
                  value: attributes.totalArea || 'N/A',
                },
              ]
            } else if (attributes.propertyType === 'area') {
              description = 'Área'
              propertyAttributes = [
                {
                  label: 'Classificação',
                  value: attributes.classification || 'N/A',
                },
                { label: 'Frente', value: attributes.front || 'N/A' },
                { label: 'Fundos', value: attributes.back || 'N/A' },
                { label: 'Direita', value: attributes.rightSide || 'N/A' },
                { label: 'Esquerda', value: attributes.leftSide || 'N/A' },
                {
                  label: 'Área Total (m²)',
                  value: attributes.totalArea || 'N/A',
                },
              ]
            } else if (attributes.propertyType === 'commercial_property') {
              description = 'Comercial'
              propertyAttributes = [
                {
                  label: 'Armários',
                  value: attributes.cabinets || 'N/A',
                },
                {
                  label: 'Banheiros',
                  value: attributes.bathrooms || 'N/A',
                },
                {
                  label: 'Conservação',
                  value: attributes.conservation || 'N/A',
                },
                {
                  label: 'Guarita',
                  value: attributes.guardhouse || 'N/A',
                },
                {
                  label: 'Salas',
                  value: attributes.numberOfRooms || 'N/A',
                },
                {
                  label: 'Cozinha',
                  value: attributes.kitchen || 'N/A',
                },
                {
                  label: 'Copa',
                  value: attributes.copa || 'N/A',
                },
                {
                  label: 'Chaves',
                  value: attributes.keys || 'N/A',
                },
                {
                  label: 'Área (m²)',
                  value: attributes.totalArea || 'N/A',
                },
              ]
            } else {
              return null
            }

            const title = `${attributes.street || ''}, ${attributes.number || ''}, ${
              attributes.neighborhood || ''
            }`
            const additionalInfo = `Região: ${attributes.region || ''}`

            return (
              <PropertyCard
                key={property.id}
                id={property.id}
                title={title}
                description={`${description} - ${typeValue}`}
                additionalInfo={additionalInfo}
                status={property.attributes.status}
                images={images}
                price={formatCurrency(attributes.price)}
                condominiumPrice={
                  attributes.condominiumFee
                    ? formatCurrency(attributes.condominiumFee)
                    : 'N/A'
                }
                iptuPrice={
                  attributes.iptu ? formatCurrency(attributes.iptu) : 'N/A'
                }
                attributes={propertyAttributes}
              />
            )
          })
        : !loading && (
            <div className="mt-10 text-center text-gray-500">
              Nenhum imóvel encontrado.
            </div>
          )}

      {!loading && (
        <div className="pagination mt-4 flex justify-center">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="mx-2 rounded bg-gray-300 px-4 py-2 disabled:opacity-50"
          >
            Anterior
          </button>
          <span className="mx-2 py-2">
            Página {currentPage} de {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="mx-2 rounded bg-gray-300 px-4 py-2 disabled:opacity-50"
          >
            Próxima
          </button>
        </div>
      )}
    </>
  )
}
