// CreateCondominiumDialog.tsx
import * as Toast from '@radix-ui/react-toast'
import React, { useEffect, useState } from 'react'

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

import { Condominium, Region } from '../form-property/property-types'
import { Button } from '../ui/button'
import { Checkbox } from '../ui/checkbox'
import { Input } from '../ui/input'
import { Label } from '../ui/label'

interface CreateCondominiumDialogProps {
  onCondominiumCreate: (condominium: Condominium) => void
  trigger?: React.ReactNode
  existingCondominium?: Condominium
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function CreateCondominiumDialog({
  onCondominiumCreate,
  trigger,
  existingCondominium,
  open,
  onOpenChange,
}: CreateCondominiumDialogProps) {
  // Campos de texto
  const [name, setName] = useState('')
  const [region, setRegion] = useState('')
  const [street, setStreet] = useState('')
  const [neighborhood, setNeighborhood] = useState('')
  const [number, setNumber] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [commercialStatus, setCommercialStatus] = useState('')
  const [builderName, setBuilderName] = useState('')
  const [developerName, setDeveloperName] = useState('')
  const [managerName, setManagerName] = useState('')
  const [managerPhone, setManagerPhone] = useState('')
  const [caretakerName, setCaretakerName] = useState('')
  const [caretakerPhone, setCaretakerPhone] = useState('')
  const [constructionYear, setConstructionYear] = useState('')
  const [numberOfServiceElevators, setNumberOfServiceElevators] = useState('')
  const [numberOfPassengerElevators, setNumberOfPassengerElevators] =
    useState('')
  const [deliverySchedule, setDeliverySchedule] = useState('')

  const [corner, setCorner] = useState(false)
  const [guardhouse, setGuardhouse] = useState(false)
  const [intercom, setIntercom] = useState(false)
  const [terrace, setTerrace] = useState(false)
  const [undergroundGarage, setUndergroundGarage] = useState(false)
  const [beachService, setBeachService] = useState(false)
  const [beachEquipmentStorage, setBeachEquipmentStorage] = useState(false)
  const [electronicGate, setElectronicGate] = useState(false)
  const [groundFloorGarage, setGroundFloorGarage] = useState(false)
  const [collectiveGas, setCollectiveGas] = useState(false)

  const [toastOpen, setToastOpen] = useState(false)
  const [toastType, setToastType] = useState<'success' | 'error'>('success')
  const [toastMessage, setToastMessage] = useState('')

  // Variáveis de estado para as opções de região e status comercial
  const [regionOptions, setRegionOptions] = useState<string[]>([])
  const [commercialStatusOptions, setCommercialStatusOptions] = useState<
    string[]
  >([])

  // Função para buscar regiões da API
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const route = import.meta.env.VITE_URL_ENDPOINT
        const response = await fetch(`${route}/api/regions`)
        const data = await response.json()
        const regions = data.data.map(
          (region: Region) => region.attributes.Name,
        )
        setRegionOptions(regions)
      } catch (error) {
        console.error('Erro ao buscar regiões:', error)
      }
    }
    fetchRegions()
  }, [])

  // Definir opções de status comercial
  useEffect(() => {
    const options = ['Disponível', 'Vendido', 'Pendente']
    setCommercialStatusOptions(options)
  }, [])

  // Função para buscar o endereço pelo CEP
  useEffect(() => {
    const fetchAddress = async () => {
      const sanitizedCep = zipCode.replace(/\D/g, '')
      if (sanitizedCep.length === 8) {
        try {
          const response = await fetch(
            `https://viacep.com.br/ws/${sanitizedCep}/json/`,
          )
          const data = await response.json()
          if (!data.erro) {
            setStreet(data.logradouro || '')
            setNeighborhood(data.bairro || '')
          } else {
            setToastType('error')
            setToastMessage('CEP não encontrado.')
            setToastOpen(true)
          }
        } catch (error) {
          console.error('Erro ao buscar o endereço:', error)
          setToastType('error')
          setToastMessage('Erro ao buscar o endereço.')
          setToastOpen(true)
        }
      }
    }
    if (zipCode) {
      fetchAddress()
    }
  }, [zipCode])

  // Preencher campos se existingCondominium for fornecido
  useEffect(() => {
    if (existingCondominium) {
      const attrs = existingCondominium.attributes
      setName(attrs.Name || '')
      setRegion(attrs.region || '')
      setStreet(attrs.street || '')
      setNeighborhood(attrs.neighborhood || '')
      setNumber(attrs.number || '')
      setZipCode(attrs.zipCode || '')
      setCommercialStatus(attrs.commercialStatus || '')
      setBuilderName(attrs.builderName || '')
      setDeveloperName(attrs.developerName || '')
      setManagerName(attrs.managerName || '')
      setManagerPhone(attrs.managerPhone || '')
      setCaretakerName(attrs.caretakerName || '')
      setCaretakerPhone(attrs.caretakerPhone || '')
      setConstructionYear(attrs.constructionYear || '')
      setNumberOfServiceElevators(attrs.numberOfServiceElevators || '')
      setNumberOfPassengerElevators(attrs.numberOfPassengerElevators || '')
      setDeliverySchedule(attrs.deliverySchedule || '')
      setCorner(attrs.corner || false)
      setGuardhouse(attrs.guardhouse || false)
      setIntercom(attrs.intercom || false)
      setTerrace(attrs.terrace || false)
      setUndergroundGarage(attrs.undergroundGarage || false)
      setBeachService(attrs.beachService || false)
      setBeachEquipmentStorage(attrs.beachEquipmentStorage || false)
      setElectronicGate(attrs.electronicGate || false)
      setGroundFloorGarage(attrs.groundFloorGarage || false)
      setCollectiveGas(attrs.collectiveGas || false)
    } else {
      // Limpar campos se não houver existingCondominium
      setName('')
      setRegion('')
      setStreet('')
      setNeighborhood('')
      setNumber('')
      setZipCode('')
      setCommercialStatus('')
      setBuilderName('')
      setDeveloperName('')
      setManagerName('')
      setManagerPhone('')
      setCaretakerName('')
      setCaretakerPhone('')
      setConstructionYear('')
      setNumberOfServiceElevators('')
      setNumberOfPassengerElevators('')
      setDeliverySchedule('')
      setCorner(false)
      setGuardhouse(false)
      setIntercom(false)
      setTerrace(false)
      setUndergroundGarage(false)
      setBeachService(false)
      setBeachEquipmentStorage(false)
      setElectronicGate(false)
      setGroundFloorGarage(false)
      setCollectiveGas(false)
    }
  }, [existingCondominium])

  async function handleCreateCondominium() {
    const newCondominium = {
      data: {
        Name: name,
        region,
        street,
        neighborhood,
        number,
        zipCode,
        commercialStatus,
        builderName,
        developerName,
        managerName,
        managerPhone,
        caretakerName,
        caretakerPhone,
        constructionYear,
        numberOfServiceElevators,
        numberOfPassengerElevators,
        deliverySchedule,
        corner,
        guardhouse,
        intercom,
        terrace,
        undergroundGarage,
        beachService,
        beachEquipmentStorage,
        electronicGate,
        groundFloorGarage,
        collectiveGas,
      },
    }

    try {
      const route = import.meta.env.VITE_URL_ENDPOINT
      const method = existingCondominium ? 'PUT' : 'POST'
      const url = existingCondominium
        ? `${route}/api/condominiums/${existingCondominium.id}`
        : `${route}/api/condominiums`
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newCondominium),
      })

      if (!response.ok) {
        const errorData = await response.json()
        const errorMessage =
          errorData?.message || 'Falha ao salvar o condomínio'
        throw new Error(errorMessage)
      }

      const newCondominiumData = await response.json()
      const savedCondominium = newCondominiumData.data

      onCondominiumCreate(savedCondominium)
      setToastType('success')
      setToastMessage(
        existingCondominium
          ? 'Condomínio atualizado com sucesso!'
          : 'Condomínio criado com sucesso!',
      )
      setToastOpen(true)
      // Fechar o diálogo após salvar
      onOpenChange && onOpenChange(false)
    } catch (error: unknown) {
      console.error('Failed to save condominium:', error)
      setToastOpen(true)
      setToastType('error')
      setToastMessage('Falha ao salvar o condomínio!')
    }
  }

  return (
    <div>
      <Toast.Provider swipeDirection="right">
        <Dialog open={open} onOpenChange={onOpenChange}>
          {trigger ? <DialogTrigger asChild>{trigger}</DialogTrigger> : null}
          <DialogContent className="max-h-[90vh] overflow-y-auto rounded-md bg-white p-6 shadow-lg sm:max-w-[800px]">
            <DialogHeader>
              <DialogTitle className="text-xl font-bold">
                {existingCondominium ? 'Editar Condomínio' : 'Criar Condomínio'}
              </DialogTitle>
              <DialogDescription className="text-sm text-gray-500">
                Preencha as informações e clique em salvar
              </DialogDescription>
            </DialogHeader>
            <Accordion type="multiple" className="w-full">
              {/* Dados Básicos */}
              <AccordionItem value="basicInfo">
                <AccordionTrigger className="text-lg font-medium">
                  Dados Básicos
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid gap-4 py-4">
                    {/* Nome */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="name" className="text-right">
                        Nome
                      </Label>
                      <Input
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    {/* Status Comercial */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="commercialStatus" className="text-right">
                        Status Comercial
                      </Label>
                      <Select
                        value={commercialStatus}
                        onValueChange={(value) => setCommercialStatus(value)}
                      >
                        <SelectTrigger className="col-span-3">
                          <SelectValue placeholder="Selecione o Status Comercial" />
                        </SelectTrigger>
                        <SelectContent>
                          {commercialStatusOptions.map((status) => (
                            <SelectItem key={status} value={status}>
                              {status}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>

              {/* Endereço */}
              <AccordionItem value="address">
                <AccordionTrigger className="text-lg font-medium">
                  Endereço
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid gap-4 py-4">
                    {/* CEP */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="zipCode" className="text-right">
                        CEP
                      </Label>
                      <Input
                        id="zipCode"
                        value={zipCode}
                        onChange={(e) => {
                          const cep = e.target.value.replace(/\D/g, '')
                          setZipCode(cep)
                        }}
                        className="col-span-3"
                        maxLength={8}
                      />
                    </div>
                    {/* Rua */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="street" className="text-right">
                        Rua
                      </Label>
                      <Input
                        id="street"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    {/* Número */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="number" className="text-right">
                        Número
                      </Label>
                      <Input
                        id="number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    {/* Bairro */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="neighborhood" className="text-right">
                        Bairro
                      </Label>
                      <Input
                        id="neighborhood"
                        value={neighborhood}
                        onChange={(e) => setNeighborhood(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    {/* Região */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="region" className="text-right">
                        Região
                      </Label>
                      <Select
                        value={region}
                        onValueChange={(value) => setRegion(value)}
                      >
                        <SelectTrigger className="col-span-3">
                          <SelectValue placeholder="Selecione a Região" />
                        </SelectTrigger>
                        <SelectContent>
                          {regionOptions.map((regionOption) => (
                            <SelectItem key={regionOption} value={regionOption}>
                              {regionOption}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>

              {/* Informações do Condomínio */}
              <AccordionItem value="condoInfo">
                <AccordionTrigger className="text-lg font-medium">
                  Informações do Condomínio
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid gap-4 py-4">
                    {/* Nome da Construtora */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="builderName" className="text-right">
                        Nome da Construtora
                      </Label>
                      <Input
                        id="builderName"
                        value={builderName}
                        onChange={(e) => setBuilderName(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    {/* Nome da Incorporadora */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="developerName" className="text-right">
                        Nome da Incorporadora
                      </Label>
                      <Input
                        id="developerName"
                        value={developerName}
                        onChange={(e) => setDeveloperName(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    {/* Ano de Construção */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="constructionYear" className="text-right">
                        Ano de Construção
                      </Label>
                      <Input
                        id="constructionYear"
                        value={constructionYear}
                        onChange={(e) => setConstructionYear(e.target.value)}
                        className="col-span-3"
                        type="number"
                      />
                    </div>
                    {/* Previsão de Entrega */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="deliverySchedule" className="text-right">
                        Previsão de Entrega
                      </Label>
                      <Input
                        id="deliverySchedule"
                        value={deliverySchedule}
                        onChange={(e) => setDeliverySchedule(e.target.value)}
                        className="col-span-3"
                        type="date"
                      />
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>

              {/* Contatos */}
              <AccordionItem value="contacts">
                <AccordionTrigger className="text-lg font-medium">
                  Contatos
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid gap-4 py-4">
                    {/* Gerente */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="managerName" className="text-right">
                        Nome do Sindico
                      </Label>
                      <Input
                        id="managerName"
                        value={managerName}
                        onChange={(e) => setManagerName(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="managerPhone" className="text-right">
                        Telefone do Sindico
                      </Label>
                      <Input
                        id="managerPhone"
                        value={managerPhone}
                        onChange={(e) => setManagerPhone(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    {/* Zelador */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="caretakerName" className="text-right">
                        Nome do Zelador
                      </Label>
                      <Input
                        id="caretakerName"
                        value={caretakerName}
                        onChange={(e) => setCaretakerName(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="caretakerPhone" className="text-right">
                        Telefone do Zelador
                      </Label>
                      <Input
                        id="caretakerPhone"
                        value={caretakerPhone}
                        onChange={(e) => setCaretakerPhone(e.target.value)}
                        className="col-span-3"
                      />
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>

              {/* Características */}
              <AccordionItem value="features">
                <AccordionTrigger className="text-lg font-medium">
                  Características
                </AccordionTrigger>
                <AccordionContent>
                  <div className="grid gap-4 py-4">
                    {/* Elevadores */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label
                        htmlFor="numberOfServiceElevators"
                        className="text-right"
                      >
                        Nº de Elevadores de Serviço
                      </Label>
                      <Input
                        id="numberOfServiceElevators"
                        value={numberOfServiceElevators}
                        onChange={(e) =>
                          setNumberOfServiceElevators(e.target.value)
                        }
                        className="col-span-3"
                        type="number"
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label
                        htmlFor="numberOfPassengerElevators"
                        className="text-right"
                      >
                        Nº de Elevadores de Passageiros
                      </Label>
                      <Input
                        id="numberOfPassengerElevators"
                        value={numberOfPassengerElevators}
                        onChange={(e) =>
                          setNumberOfPassengerElevators(e.target.value)
                        }
                        className="col-span-3"
                        type="number"
                      />
                    </div>
                    {/* Campos booleanos */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="corner" className="text-right">
                        Esquina
                      </Label>
                      <Checkbox
                        id="corner"
                        checked={corner}
                        onCheckedChange={(checked) =>
                          setCorner(checked === true)
                        }
                        className="col-span-3"
                      />
                    </div>
                    {/* Guarita */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="guardhouse" className="text-right">
                        Guarita
                      </Label>
                      <Checkbox
                        id="guardhouse"
                        checked={guardhouse}
                        onCheckedChange={(checked) =>
                          setGuardhouse(checked === true)
                        }
                        className="col-span-3"
                      />
                    </div>
                    {/* Interfone */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="intercom" className="text-right">
                        Interfone
                      </Label>
                      <Checkbox
                        id="intercom"
                        checked={intercom}
                        onCheckedChange={(checked) =>
                          setIntercom(checked === true)
                        }
                        className="col-span-3"
                      />
                    </div>
                    {/* Terraço */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="terrace" className="text-right">
                        Terraço
                      </Label>
                      <Checkbox
                        id="terrace"
                        checked={terrace}
                        onCheckedChange={(checked) =>
                          setTerrace(checked === true)
                        }
                        className="col-span-3"
                      />
                    </div>
                    {/* Garagem Subterrânea */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="undergroundGarage" className="text-right">
                        Garagem Subterrânea
                      </Label>
                      <Checkbox
                        id="undergroundGarage"
                        checked={undergroundGarage}
                        onCheckedChange={(checked) =>
                          setUndergroundGarage(checked === true)
                        }
                        className="col-span-3"
                      />
                    </div>
                    {/* Serviço de Praia */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="beachService" className="text-right">
                        Serviço de Praia
                      </Label>
                      <Checkbox
                        id="beachService"
                        checked={beachService}
                        onCheckedChange={(checked) =>
                          setBeachService(checked === true)
                        }
                        className="col-span-3"
                      />
                    </div>
                    {/* Depósito de Equipamentos de Praia */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label
                        htmlFor="beachEquipmentStorage"
                        className="text-right"
                      >
                        Depósito para Equipamentos de Praia
                      </Label>
                      <Checkbox
                        id="beachEquipmentStorage"
                        checked={beachEquipmentStorage}
                        onCheckedChange={(checked) =>
                          setBeachEquipmentStorage(checked === true)
                        }
                        className="col-span-3"
                      />
                    </div>
                    {/* Portão Eletrônico */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="electronicGate" className="text-right">
                        Portão Eletrônico
                      </Label>
                      <Checkbox
                        id="electronicGate"
                        checked={electronicGate}
                        onCheckedChange={(checked) =>
                          setElectronicGate(checked === true)
                        }
                        className="col-span-3"
                      />
                    </div>
                    {/* Garagem no Térreo */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="groundFloorGarage" className="text-right">
                        Garagem no Térreo
                      </Label>
                      <Checkbox
                        id="groundFloorGarage"
                        checked={groundFloorGarage}
                        onCheckedChange={(checked) =>
                          setGroundFloorGarage(checked === true)
                        }
                        className="col-span-3"
                      />
                    </div>
                    {/* Gás Encanado */}
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="collectiveGas" className="text-right">
                        Gás Encanado
                      </Label>
                      <Checkbox
                        id="collectiveGas"
                        checked={collectiveGas}
                        onCheckedChange={(checked) =>
                          setCollectiveGas(checked === true)
                        }
                        className="col-span-3"
                      />
                    </div>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
            <DialogFooter>
              <Button type="button" onClick={handleCreateCondominium}>
                Salvar
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Toast.Root
          open={toastOpen}
          onOpenChange={setToastOpen}
          duration={5000}
          className={`fixed bottom-0 right-0 m-6 rounded-md p-4 shadow-md ${
            toastType === 'success'
              ? 'bg-green-500 text-white'
              : 'bg-red-500 text-white'
          }`}
        >
          <Toast.Description>{toastMessage}</Toast.Description>
          <Toast.Close className="absolute right-1 top-1" />
        </Toast.Root>
        <Toast.Viewport />
      </Toast.Provider>
    </div>
  )
}
