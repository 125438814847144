// Area.tsx

import React from 'react'

import { AreaData } from '@/components/form-property/property-types'
import { Checkbox } from '@/components/ui/checkbox'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

interface AreaProps {
  data: AreaData
  onChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => void
  onCheckboxChange: (
    name: keyof AreaData,
    checked: boolean | 'indeterminate',
  ) => void
}

export function Area({ data, onChange, onCheckboxChange }: AreaProps) {
  const handleSelectChange = (field: keyof AreaData, value: string) => {
    const event = {
      target: {
        name: field,
        value,
      },
    } as React.ChangeEvent<HTMLInputElement>
    onChange(event)
  }

  return (
    <div className="flex h-full w-full flex-col bg-gray-100 p-8">
      <h1 className="mb-6 text-center text-3xl font-bold text-gray-800">
        Detalhes da Área
      </h1>

      <div className="rounded-lg bg-white p-6 shadow-md">
        <div>
          <Label htmlFor="classification" className="text-gray-600">
            Classificação
          </Label>
          <Select
            value={data.classification || ''}
            onValueChange={(value: string) =>
              handleSelectChange('classification', value)
            }
          >
            <SelectTrigger
              id="classification"
              name="classification"
              className="mb-4 mt-1 w-1/4 rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <SelectValue placeholder="Classificação" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Residencial">Residencial</SelectItem>
              <SelectItem value="Comercial">Comercial</SelectItem>
              <SelectItem value="Industrial">Industrial</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <div>
          <Label htmlFor="type" className="text-gray-600">
            Tipo
          </Label>
          <Select
            value={data.type || ''}
            onValueChange={(value: string) => handleSelectChange('type', value)}
          >
            <SelectTrigger
              id="type"
              name="type"
              className="mb-4 mt-1 w-1/4 rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <SelectValue placeholder="Tipo" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="Terreno">Terreno</SelectItem>
              <SelectItem value="Lote">Lote</SelectItem>
              <SelectItem value="Área">Área</SelectItem>
            </SelectContent>
          </Select>
        </div>

        <h2 className="mb-4 mt-6 text-xl font-semibold text-gray-700">
          Dimensões
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div>
            <Label htmlFor="front" className="text-gray-600">
              Frente (m)
            </Label>
            <Input
              type="text"
              id="front"
              name="front"
              placeholder="Frente (m)"
              value={data.front}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <Label htmlFor="back" className="text-gray-600">
              Fundo (m)
            </Label>
            <Input
              type="text"
              id="back"
              name="back"
              placeholder="Fundo (m)"
              value={data.back}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <Label htmlFor="rightSide" className="text-gray-600">
              Lado Direito (m)
            </Label>
            <Input
              type="text"
              id="rightSide"
              name="rightSide"
              placeholder="Lado Direito (m)"
              value={data.rightSide}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div>
            <Label htmlFor="leftSide" className="text-gray-600">
              Lado Esquerdo (m)
            </Label>
            <Input
              type="text"
              id="leftSide"
              name="leftSide"
              placeholder="Lado Esquerdo (m)"
              value={data.leftSide}
              onChange={onChange}
              className="mt-1 block w-full rounded-md border-gray-300 bg-white shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>
        </div>

        <h2 className="mb-4 mt-6 text-xl font-semibold text-gray-700">
          Características
        </h2>
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="corner"
              name="corner"
              checked={data.corner}
              onCheckedChange={(checked) => onCheckboxChange('corner', checked)}
            />
            <Label htmlFor="corner" className="text-gray-600">
              Esquina
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="leveled"
              name="leveled"
              checked={data.leveled}
              onCheckedChange={(checked) =>
                onCheckboxChange('leveled', checked)
              }
            />
            <Label htmlFor="leveled" className="text-gray-600">
              Nivelado
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="fenced"
              name="fenced"
              checked={data.fenced}
              onCheckedChange={(checked) => onCheckboxChange('fenced', checked)}
            />
            <Label htmlFor="fenced" className="text-gray-600">
              Cercado
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="waterSupply"
              name="waterSupply"
              checked={data.waterSupply}
              onCheckedChange={(checked) =>
                onCheckboxChange('waterSupply', checked)
              }
            />
            <Label htmlFor="waterSupply" className="text-gray-600">
              Abastecimento de Água
            </Label>
          </div>

          <div className="flex items-center space-x-2">
            <Checkbox
              id="electricitySupply"
              name="electricitySupply"
              checked={data.electricitySupply}
              onCheckedChange={(checked) =>
                onCheckboxChange('electricitySupply', checked)
              }
            />
            <Label htmlFor="electricitySupply" className="text-gray-600">
              Abastecimento de Energia
            </Label>
          </div>
        </div>
      </div>
    </div>
  )
}
