// CondominiumSelector.tsx

import { useEffect, useState } from 'react'

import type {
  Block,
  Condominium,
  PropertyFormData,
} from '@/components/form-property/property-types'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { useToast } from '@/components/ui/use-toast'
import { useDebounce } from '@/hooks/useDebounce'

import { CreateBlockDialog } from '../Block/CreateBlockDialog'
import { CreateCondominiumDialog } from './CondominiumCreateDialog'

interface CondominiumSelectorProps {
  setFormData: React.Dispatch<React.SetStateAction<PropertyFormData>>
  formData: PropertyFormData
  setSelectedCondominium: React.Dispatch<
    React.SetStateAction<Condominium | undefined>
  >
  selectedCondominium?: Condominium | null
}

export function CondominiumSelector({
  setFormData,
  formData,
  setSelectedCondominium,
  selectedCondominium,
}: CondominiumSelectorProps) {
  const { toast } = useToast()
  const [isInCondominium, setIsInCondominium] = useState<'Sim' | 'Não' | ''>('')
  const [condominiumSelected, setCondominiumSelected] =
    useState<Condominium | null>(null)
  const [condominiumSearchTerm, setCondominiumSearchTerm] = useState('')
  const [filteredCondominiums, setFilteredCondominiums] = useState<
    Condominium[]
  >([])
  const [blocks, setBlocks] = useState<Block[]>([])
  const [selectedBlockId, setSelectedBlockId] = useState<number | null>(null)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [selectedBlock, setSelectedBlock] = useState<Block | undefined>(
    undefined,
  )
  const debouncedCondominiumSearchTerm =
    useDebounce(condominiumSearchTerm, 500) || ''
  const route = import.meta.env.VITE_URL_ENDPOINT

  // Ajusta o estado interno quando selectedCondominium prop muda
  useEffect(() => {
    if (selectedCondominium) {
      setCondominiumSelected(selectedCondominium)
      setCondominiumSearchTerm(selectedCondominium.attributes.Name)
      setIsInCondominium('Sim')

      // Após selecionar o condomínio, verifica se há um bloco já selecionado no formData
      if (formData.block) {
        setSelectedBlockId(formData.block)
      }
    }
  }, [selectedCondominium, formData.block])

  // Busca condomínios com base no termo de busca debounced
  useEffect(() => {
    if (isInCondominium !== 'Sim') {
      return
    }
    if (
      debouncedCondominiumSearchTerm &&
      debouncedCondominiumSearchTerm.length >= 3
    ) {
      const fetchFilteredCondominiums = async () => {
        try {
          const response = await fetch(
            `${route}/api/condominiums?filters[Name][$containsi]=${debouncedCondominiumSearchTerm}`,
          )
          const result = await response.json()
          const condominiumsData = result.data || []
          setFilteredCondominiums(condominiumsData)
        } catch (error) {
          console.error('Erro ao buscar condomínios:', error)
          toast({
            title: 'Erro',
            description: 'Erro ao buscar condomínios.',
            variant: 'destructive',
          })
          setFilteredCondominiums([])
        }
      }

      fetchFilteredCondominiums()
    } else {
      setFilteredCondominiums([])
    }
  }, [debouncedCondominiumSearchTerm, route, toast, isInCondominium])

  // Busca blocos com base no condomínio selecionado
  useEffect(() => {
    if (condominiumSelected) {
      const fetchBlocks = async () => {
        try {
          const response = await fetch(
            `${route}/api/blocks?filters[condominium][id][$eq]=${condominiumSelected.id}`,
          )
          const result = await response.json()
          const blocksData = result.data || []
          setBlocks(blocksData)

          // Após buscar os blocos, verifica se o formData.block está definido e seleciona automaticamente
          if (formData.block) {
            const existingBlock = blocksData.find(
              (block: Block) => block.id === formData.block,
            )
            if (existingBlock) {
              setSelectedBlockId(existingBlock.id)
              setSelectedBlock(existingBlock)
            } else {
              // Se o bloco não for encontrado nos blocos filtrados, reseta o campo block
              setFormData((prevState) => ({
                ...prevState,
                block: null,
              }))
              setSelectedBlockId(null)
              setSelectedBlock(undefined)
              toast({
                title: 'Bloco não encontrado',
                description: 'O bloco associado não foi encontrado.',
                variant: 'destructive',
              })
            }
          }
        } catch (error) {
          console.error('Erro ao buscar blocos:', error)
          toast({
            title: 'Erro',
            description: 'Erro ao buscar blocos.',
            variant: 'destructive',
          })
          setBlocks([])
        }
      }

      fetchBlocks()
    } else {
      setBlocks([])
      setSelectedBlockId(null)
      setSelectedBlock(undefined)
    }
  }, [condominiumSelected, route, toast, formData.block, setFormData])

  const handleCondominiumSelect = (condominium: Condominium) => {
    setCondominiumSelected(condominium)
    setSelectedCondominium(condominium)
    setFormData((prevState) => ({
      ...prevState,
      condominium: condominium.id, // Número conforme o tipo
      block: null, // Define como null para alinhar com o tipo
      region: condominium.attributes.region || '',
      street: condominium.attributes.street || '',
      neighborhood: condominium.attributes.neighborhood || '',
      number: condominium.attributes.number || '',
      zipCode: condominium.attributes.zipCode || '',
    }))
    setCondominiumSearchTerm(condominium.attributes.Name)
    setFilteredCondominiums([])
    setSelectedBlockId(null)
    setSelectedBlock(undefined)
  }

  const handleCondominiumCreate = (condominium: Condominium) => {
    setCondominiumSelected(condominium)
    setSelectedCondominium(condominium)
    setFormData((prevState) => ({
      ...prevState,
      condominium: condominium.id, // Número conforme o tipo
      block: null, // Define como null para alinhar com o tipo
      region: condominium.attributes.region || '',
      street: condominium.attributes.street || '',
      neighborhood: condominium.attributes.neighborhood || '',
      number: condominium.attributes.number || '',
      zipCode: condominium.attributes.zipCode || '',
    }))
    setCondominiumSearchTerm(condominium.attributes.Name)
    setFilteredCondominiums([])
    setSelectedBlockId(null)
    setSelectedBlock(undefined)
  }

  const handleCondominiumUpdate = (condominium: Condominium) => {
    setCondominiumSelected(condominium)
    setSelectedCondominium(condominium)
    setFormData((prevState) => ({
      ...prevState,
      condominium: condominium.id, // Número conforme o tipo
      region: condominium.attributes.region || '',
      street: condominium.attributes.street || '',
      neighborhood: condominium.attributes.neighborhood || '',
      number: condominium.attributes.number || '',
      zipCode: condominium.attributes.zipCode || '',
    }))
    setCondominiumSearchTerm(condominium.attributes.Name)
    setFilteredCondominiums([])
  }

  const handleIsInCondominiumChange = (value: 'Sim' | 'Não') => {
    setIsInCondominium(value)
    if (value === 'Não') {
      setCondominiumSelected(null)
      setSelectedCondominium(undefined)
      setCondominiumSearchTerm('')
      setFilteredCondominiums([])
      setBlocks([])
      setSelectedBlockId(null)
      setSelectedBlock(undefined)
      setFormData((prevState) => ({
        ...prevState,
        condominium: null, // Define como null
        block: null, // Define como null
      }))
    }
  }

  const handleBlockSelect = (blockId: number) => {
    setSelectedBlockId(blockId)
    setFormData((prevState) => ({
      ...prevState,
      block: blockId,
    }))
    const selectedBlock = blocks.find((block) => block.id === blockId)
    setSelectedBlock(selectedBlock)
  }

  const handleBlockCreate = (block: Block) => {
    setBlocks((prevBlocks) => [...prevBlocks, block])
    setSelectedBlockId(block.id)
    setFormData((prevState) => ({
      ...prevState,
      block: block.id,
    }))
    setSelectedBlock(block)
  }

  const handleBlockUpdate = (updatedBlock: Block) => {
    setBlocks((prevBlocks) =>
      prevBlocks.map((block) =>
        block.id === updatedBlock.id ? updatedBlock : block,
      ),
    )
    if (selectedBlockId === updatedBlock.id) {
      setSelectedBlock(updatedBlock)
    }
  }

  return (
    <div className="mb-5">
      <div className="mb-4">
        <Label htmlFor="isInCondominium" className="text-gray-600">
          Está em um condomínio?
        </Label>
        <Select
          value={isInCondominium}
          onValueChange={(value) =>
            handleIsInCondominiumChange(value as 'Sim' | 'Não')
          }
        >
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Selecione" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="Sim">Sim</SelectItem>
            <SelectItem value="Não">Não</SelectItem>
          </SelectContent>
        </Select>
      </div>

      {isInCondominium === 'Sim' && (
        <>
          <Label htmlFor="condominium" className="text-gray-600">
            Condomínio
          </Label>
          <div className="flex items-center">
            <Input
              type="text"
              id="condominium"
              name="condominium"
              value={condominiumSearchTerm}
              onChange={(e) => {
                setCondominiumSearchTerm(e.target.value)
                setCondominiumSelected(null)
                setSelectedCondominium(undefined)
                setBlocks([])
                setSelectedBlockId(null)
                setSelectedBlock(undefined)
              }}
              placeholder="Digite o nome do condomínio"
              className="w-full"
            />
            <CreateCondominiumDialog
              onCondominiumCreate={handleCondominiumCreate}
              trigger={
                <span className="ml-2 cursor-pointer text-blue-500 hover:underline">
                  Adicionar Condomínio
                </span>
              }
            />
          </div>
          {debouncedCondominiumSearchTerm &&
            debouncedCondominiumSearchTerm.length >= 3 &&
            !condominiumSelected && (
              <div className="border border-gray-300 bg-white shadow-md">
                {filteredCondominiums.length > 0 ? (
                  filteredCondominiums.map((condominium) => (
                    <div
                      key={condominium.id}
                      onClick={() => handleCondominiumSelect(condominium)}
                      className="cursor-pointer p-2 hover:bg-gray-100"
                    >
                      {condominium.attributes.Name}
                    </div>
                  ))
                ) : (
                  <div className="p-2 text-gray-500">
                    Condomínio não encontrado
                  </div>
                )}
              </div>
            )}

          {condominiumSelected && (
            <>
              <div className="mt-4 flex items-center">
                <p className="mr-2 text-gray-600">
                  Condomínio selecionado: {condominiumSelected.attributes.Name}
                </p>
                <Button
                  onClick={() => {
                    setIsEditDialogOpen(true)
                  }}
                >
                  Editar Condomínio
                </Button>
                <CreateCondominiumDialog
                  open={isEditDialogOpen}
                  onOpenChange={(open) => setIsEditDialogOpen(open)}
                  existingCondominium={condominiumSelected}
                  onCondominiumCreate={handleCondominiumUpdate}
                />
              </div>

              <div className="mt-4">
                <Label htmlFor="block" className="text-gray-600">
                  Nome do Edifício/bloco/torre
                </Label>
                {blocks.length > 0 ? (
                  <>
                    <Select
                      value={selectedBlockId?.toString() || ''}
                      onValueChange={(value) =>
                        handleBlockSelect(Number(value))
                      }
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Selecione um Edifício/bloco/torre" />
                      </SelectTrigger>
                      <SelectContent>
                        {blocks.map((block) => (
                          <SelectItem
                            key={block.id}
                            value={block.id.toString()}
                          >
                            {block.attributes.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <div className="mt-2 flex space-x-2">
                      {selectedBlock && (
                        <CreateBlockDialog
                          existingBlock={selectedBlock}
                          condominiumId={condominiumSelected.id}
                          onBlockCreate={handleBlockUpdate}
                          trigger={<Button>Editar Edifício/bloco/torre</Button>}
                        />
                      )}
                      <CreateBlockDialog
                        condominiumId={condominiumSelected.id}
                        onBlockCreate={handleBlockCreate}
                        trigger={
                          <Button>Adicionar Edifício/bloco/torre</Button>
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <p className="text-gray-600">
                      Nenhum Edifício/bloco/torre encontrado.
                    </p>
                    <CreateBlockDialog
                      condominiumId={condominiumSelected.id}
                      onBlockCreate={handleBlockCreate}
                      trigger={<Button>Adicionar Edifício/bloco/torre</Button>}
                    />
                  </>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
