// PropertyCard.tsx

import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import type { Attribute } from '@/components/form-property/property-types'

type PropertyCardProps = {
  id: number
  title: string
  description: string
  images: string[]
  price?: string
  status: string
  condominiumPrice?: string
  iptuPrice?: string
  attributes: Attribute[]
  additionalInfo: string
}

export const PropertyCard = ({
  id,
  title,
  description,
  images,
  price = 'R$ 0,00',
  condominiumPrice = 'N/A',
  iptuPrice = 'N/A',
  attributes = [],
  additionalInfo,
  status,
}: PropertyCardProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const userCollaborator = localStorage.getItem('userCollaborator')
  const isAdmin = userCollaborator === 'ADMIN'

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1,
    )
  }

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    )
  }

  const handleDelete = async () => {
    const confirmDelete = confirm(
      'Tem certeza que deseja excluir esta propriedade?',
    )
    if (!confirmDelete) return

    try {
      const route = import.meta.env.VITE_URL_ENDPOINT
      const authToken = localStorage.getItem('authToken')
      if (!authToken) {
        alert('Usuário não autenticado.')
        return
      }

      const response = await fetch(`${route}/api/properties/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })

      if (!response.ok) {
        throw new Error('Falha ao excluir a propriedade.')
      }

      alert('Propriedade excluída com sucesso!')
      // Recarrega a página ou remova o card da lista no componente pai
      window.location.reload()
    } catch (error) {
      console.error('Erro ao excluir a propriedade:', error)
      alert('Erro ao excluir a propriedade. Verifique o console.')
    }
  }

  return (
    <div className="bg-white-100 mt-10 flex items-center justify-center">
      <div className="relative flex h-[300px] w-[800px] overflow-hidden border bg-white shadow-lg">
        <div className="relative h-full w-1/2">
          {images.length > 0 ? (
            <img
              className="h-full w-full object-cover"
              src={images[currentImageIndex]}
              alt={title}
            />
          ) : (
            <div className="flex h-full w-full items-center justify-center bg-gray-200">
              Sem imagem disponível
            </div>
          )}
          {images.length > 1 && (
            <>
              <button
                onClick={handlePrevImage}
                className="absolute left-2 top-1/2 -translate-y-1/2 transform rounded-full bg-white bg-opacity-50 p-1"
              >
                &#10094;
              </button>
              <button
                onClick={handleNextImage}
                className="absolute right-2 top-1/2 -translate-y-1/2 transform rounded-full bg-white bg-opacity-50 p-1"
              >
                &#10095;
              </button>
            </>
          )}
          <div className="absolute bottom-0 left-0 bg-black bg-opacity-60 p-2 text-white">
            <p className="text-lg font-bold">{price}</p>
            {condominiumPrice && (
              <p className="text-sm">Condomínio {condominiumPrice}</p>
            )}
            {iptuPrice && <p className="text-sm">IPTU {iptuPrice}</p>}
          </div>
        </div>

        <div className="relative flex h-full w-1/2 flex-col justify-between p-4">
          <div>
            <h2 className="text-lg font-bold text-gray-800">{title}</h2>
            <p className="text-sm text-gray-500">
              {description} - <b className="text-gray-800">{status}</b>
            </p>
            {additionalInfo && (
              <p className="mt-2 text-sm text-gray-600">{additionalInfo}</p>
            )}
          </div>

          <div className="flex flex-grow flex-wrap items-center justify-center gap-6">
            {attributes.map((attr, index) => (
              <span key={index} className="text-center">
                <p className="text-lg font-bold text-gray-800">{attr.value}</p>
                <p className="text-sm text-gray-500">{attr.label}</p>
              </span>
            ))}
          </div>

          <div className="mt-4 flex items-center justify-between space-x-2">
            <Link
              to={`/propriedade/${id}`}
              className="rounded bg-green-700 px-4 py-2 text-sm text-white hover:bg-green-800"
            >
              Detalhes
            </Link>
            {isAdmin && (
              <button
                onClick={handleDelete}
                className="text-gray-600 hover:text-red-700"
                title="Excluir propriedade"
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
