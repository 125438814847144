import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import BackgroundSign from '@/assets/backgroundSign.png'
import Logo from '@/assets/logo.png'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

interface HeaderProps {
  filterContent: ReactNode
}

export function Header({ filterContent }: HeaderProps) {
  const navigate = useNavigate()
  const userCollaborator = localStorage.getItem('userCollaborator')
  const isAdmin = userCollaborator === 'ADMIN'

  return (
    <div className="relative flex h-96 w-full items-center justify-center bg-gray-50">
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${BackgroundSign})` }}
      />
      <div className="absolute inset-0 bg-black bg-opacity-10" />
      <div className="absolute inset-0 flex flex-col justify-between p-8">
        <div className="flex items-center justify-between">
          <button onClick={() => navigate('/home')}>
            <img
              src={Logo}
              width={200}
              alt="Logo"
              className="z-10"
              style={{ filter: 'brightness(0) invert(1)' }}
            />
          </button>
          {isAdmin && (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">Opções Administrador</Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent>
                <DropdownMenuItem onClick={() => navigate('/usuarios')}>
                  Cadastro de Usuários
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => navigate('/regioes')}>
                  Cadastro de Região
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => navigate('/proprietarios')}>
                  Cadastro de Proprietário
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={() => navigate('/cadastro-propriedade')}
                >
                  Cadastro de Propriedade
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
        <div className="flex h-full items-center justify-center">
          <div className="w-4/5 max-w-6xl bg-white bg-opacity-80 p-16 shadow-lg">
            {filterContent}
          </div>
        </div>
      </div>
    </div>
  )
}
