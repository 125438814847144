// PropertyControl.tsx

import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { CondominiumSelector } from '@/components/Condominium/ComdominiumSelector'
import { Address } from '@/components/form-property/address'
import { Leisure } from '@/components/form-property/leisure'
import { PhotoUpload } from '@/components/form-property/PhotoUpload'
import type {
  Condominium,
  LeisurePayload,
  PropertyFormData as FormPropertyFormData,
  PropertyPayload,
} from '@/components/form-property/property-types'
import { PropertyOptions } from '@/components/form-property/PropertyOptions'
import { PropertyTypeForm } from '@/components/form-property/PropertyTypeForm'
import { OwnerSelector } from '@/components/owner/OwnerSelector'
import { Button } from '@/components/ui/button'
import { Toaster } from '@/components/ui/toaster'
import { useToast } from '@/components/ui/use-toast'

export function PropertyControl() {
  const navigate = useNavigate()
  const { toast } = useToast()
  const [formData, setFormData] = useState<FormPropertyFormData>({
    id: 0,
    region: '',
    street: '',
    neighborhood: '',
    number: '',
    apartmentNumber: '',
    zipCode: '',
    totalArea: '',
    usableArea: '',
    commonArea: '',
    privateArea: '',
    status: '',
    condominium: 0,
    internalObservation: '',
    externalObservation: '',
    distanceFromBeach: '',
    price: '',
    condominiumFee: '',
    barter: false,
    financing: false,
    paymentMethod: '',
    iptu: '',
    seaView: false,
    owner: null,
    block: null,
    propertyType: '',
    photos: { connect: [] },
    leisure: {
      id: 0,
      partyHall: false,
      adultPool: false,
      gameRoom: false,
      kidsPool: false,
      gym: false,
      playground: false,
      sportsCourt: false,
      sauna: false,
      tennisCourt: false,
      bbqArea: false,
      squashCourt: false,
      socialHall: false,
      wetDeckPool: false,
      lapPool: false,
      kidsBeachPool: false,
      solarium: false,
      poolBar: false,
      massageRoom: false,
      womenSpace: false,
      petPlace: false,
      youthGameRoom: false,
      toyLibrary: false,
      pilatesSpace: false,
      lounge: false,
      gourmetSpace: false,
      kidsPartyRoom: false,
      adultPartyRoomWithBar: false,
      adultGameRoom: false,
      readingRoom: false,
      hammockArea: false,
      zenSpace: false,
      coworkingSpace: false,
      adultTVRoom: false,
      gourmetBar: false,
      cinemaRoom: false,
      property: { data: null },
    },
    condominiumData: undefined,
    apartment: undefined,
    house: undefined,
    commercial_property: undefined,
    area: undefined,
  })

  const [selectedFiles, setSelectedFiles] = useState<File[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedCondominium, setSelectedCondominium] = useState<
    Condominium | undefined
  >(undefined)

  /**
   * Handler genérico para atualizar campos comuns no formData.
   */
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    const { name, value, type } = e.target

    let newValue: string | number | boolean | null = value

    if (type === 'checkbox') {
      const target = e.target as HTMLInputElement
      newValue = target.checked
    }

    if (name === 'owner' || name === 'block') {
      newValue = Number(value)
      if (isNaN(newValue)) {
        newValue = null
      }
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }))
  }

  const handleLeisureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    const fieldName = name as keyof FormPropertyFormData['leisure']

    setFormData((prevState) => ({
      ...prevState,
      leisure: {
        ...prevState.leisure,
        [fieldName]: checked,
      },
    }))
  }

  /**
   * Handler para checkboxes de lazer com possibilidade de estado indeterminado.
   */
  const handleLeisureCheckboxChange = (
    name: keyof FormPropertyFormData['leisure'],
    checked: boolean | 'indeterminate',
  ) => {
    setFormData((prevState) => ({
      ...prevState,
      leisure: {
        ...prevState.leisure,
        [name]: checked === true,
      },
    }))
  }

  /**
   * Handler para submissão do formulário.
   */
  const handleSubmit = async () => {
    setLoading(true)
    const route = import.meta.env.VITE_URL_ENDPOINT
    try {
      console.log('Dados atuais do formulário antes do envio:', formData)

      let photoIds: number[] = []
      if (selectedFiles.length > 0) {
        const formDataPhotos = new FormData()
        selectedFiles.forEach((file) => {
          formDataPhotos.append('files', file)
        })

        const uploadResponse = await fetch(`${route}/api/upload`, {
          method: 'POST',
          body: formDataPhotos,
        })

        if (!uploadResponse.ok) {
          const uploadError = await uploadResponse.json()
          console.error('Erro ao fazer upload das fotos:', uploadError)
          throw new Error('Erro ao fazer upload das fotos.')
        }

        const uploadResult = await uploadResponse.json()
        if (Array.isArray(uploadResult)) {
          photoIds = uploadResult.map((photo: { id: number }) => photo.id)
        } else {
          console.error(
            'Formato inesperado da resposta de upload:',
            uploadResult,
          )
          throw new Error('Formato inesperado da resposta de upload.')
        }
      }

      // 1. Criar Lazer (Leisure) primeiro
      const leisurePayload: LeisurePayload = {
        data: {
          ...formData.leisure,
        },
      }

      console.log(
        'Dados enviados para a API (Lazer):',
        JSON.stringify(leisurePayload, null, 2),
      )

      const leisureResponse = await fetch(`${route}/api/leisures`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(leisurePayload),
      })

      const leisureResult = await leisureResponse.json()
      if (!leisureResponse.ok) {
        console.error('Erro ao cadastrar os dados de lazer:', leisureResult)
        throw new Error('Erro ao cadastrar os dados de lazer.')
      }

      const leisureId = leisureResult.data.id
      console.log('Lazer cadastrado com sucesso, ID:', leisureId)

      // 2. Preparar o Payload da Propriedade incluindo leisure e condominium
      const propertyPayload: PropertyPayload = {
        data: {
          region: formData.region,
          street: formData.street,
          neighborhood: formData.neighborhood,
          number: formData.number,
          zipCode: formData.zipCode,
          totalArea: formData.totalArea,
          usableArea: formData.usableArea,
          commonArea: formData.commonArea,
          privateArea: formData.privateArea,
          status: formData.status,
          internalObservation: formData.internalObservation,
          externalObservation: formData.externalObservation,
          distanceFromBeach: formData.distanceFromBeach,
          price: formData.price,
          condominiumFee: formData.condominiumFee,
          barter: formData.barter,
          financing: formData.financing,
          paymentMethod: formData.paymentMethod,
          iptu: formData.iptu,
          seaView: formData.seaView,
          owner: formData.owner,
          ...(formData.block ? { block: formData.block } : { block: null }),
          propertyType: formData.propertyType,
          photos: photoIds,
          leisure: leisureId,
          ...(selectedCondominium
            ? { condominium: selectedCondominium.id }
            : {}),
          ...(formData.propertyType === 'apartment' && formData.apartment
            ? { ...(formData.apartment as object) }
            : {}),
          ...(formData.propertyType === 'house' && formData.house
            ? { ...(formData.house as object) }
            : {}),
          ...(formData.propertyType === 'commercial_property' &&
          formData.commercial_property
            ? { ...(formData.commercial_property as object) }
            : {}),
          ...(formData.propertyType === 'area' && formData.area
            ? { ...(formData.area as object) }
            : {}),
        },
      }

      console.log(
        'Dados enviados para a API (Propriedade):',
        JSON.stringify(propertyPayload, null, 2),
      )

      const propertyResponse = await fetch(`${route}/api/properties`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(propertyPayload),
      })

      const propertyResult = await propertyResponse.json()
      if (!propertyResponse.ok) {
        console.error('Erro ao cadastrar a propriedade:', propertyResult)
        throw new Error('Erro ao cadastrar a propriedade.')
      }

      const propertyId = propertyResult.data.id
      console.log('Propriedade cadastrada com sucesso, ID:', propertyId)

      toast({
        title: 'Sucesso',
        description: 'Propriedade cadastrada com sucesso!',
      })
      setTimeout(() => {
        navigate('/home')
      }, 2000)
    } catch (error) {
      console.error('Erro ao enviar os dados:', error)
      toast({
        title: 'Erro',
        description:
          'Erro ao cadastrar a propriedade. Por favor, tente novamente.',
        variant: 'destructive',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex h-full w-full flex-col bg-gray-100 p-8">
      <h1 className="mb-6 text-center text-3xl font-bold text-gray-800">
        Cadastro de Propriedades
      </h1>
      <div className="rounded-lg bg-white p-6 shadow-md">
        <OwnerSelector setFormData={setFormData} />
        <CondominiumSelector
          formData={formData}
          setFormData={setFormData}
          setSelectedCondominium={setSelectedCondominium}
        />
        <Address
          data={formData}
          onChange={handleChange}
          condominium={selectedCondominium}
        />
        <PropertyOptions formData={formData} setFormData={setFormData} />
        <PropertyTypeForm formData={formData} setFormData={setFormData} />
        <Leisure
          data={formData.leisure}
          onChange={handleLeisureChange}
          onCheckboxChange={handleLeisureCheckboxChange}
        />
        <PhotoUpload
          selectedFiles={selectedFiles}
          setSelectedFiles={setSelectedFiles}
        />
        <div className="mt-6 flex justify-end">
          <Button
            onClick={handleSubmit}
            disabled={loading}
            className="bg-indigo-600 text-white hover:bg-indigo-700"
          >
            {loading ? 'Enviando...' : 'Enviar'}
          </Button>
        </div>
      </div>

      <Toaster />
    </div>
  )
}
