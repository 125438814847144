import { Search } from 'lucide-react'
import { useEffect, useState } from 'react'

import { Header } from '@/components/Header'
import { RegionCard } from '@/components/region/RegionCard'
import { CreateRegionDialog } from '@/components/region/RegionCreateDialog'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

interface RegionAttributes {
  Name: string
  Observation: string
  createdAt: string
  updatedAt: string
  publishedAt: string
}

interface RegionData {
  id: number
  attributes: RegionAttributes
}

interface ApiResponse {
  data: RegionData[]
  meta: {
    pagination: {
      page: number
      pageSize: number
      pageCount: number
      total: number
    }
  }
}

interface Region {
  id: number
  Name: string
  Observation: string
}

export function RegionControl() {
  const [regions, setRegions] = useState<Region[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [filterType, setFilterType] = useState<'name' | 'observation'>('name')
  const route = import.meta.env.VITE_URL_ENDPOINT
  const fetchRegions = async () => {
    try {
      const response = await fetch(`${route}/api/regions`)
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const data: ApiResponse = await response.json()
      const formattedRegions = data.data.map((region: RegionData) => ({
        id: region.id,
        Name: region.attributes.Name,
        Observation: region.attributes.Observation,
      }))
      setRegions(formattedRegions)
    } catch (error) {
      console.error('Failed to fetch regions:', error)
    }
  }

  useEffect(() => {
    fetchRegions()
  }, [])

  const filteredRegions = regions.filter((region) => {
    const searchField = filterType === 'name' ? region.Name : region.Observation
    return searchField.toLowerCase().includes(searchTerm.toLowerCase())
  })

  const headerContent = (
    <div className="w-full max-w-4xl">
      <h1 className="-mt-5 mb-5 text-center text-2xl font-bold">
        Cadastro de Regiões
      </h1>
      <div className="flex flex-col space-y-4">
        <div className="flex space-x-4">
          <div className="relative flex-grow">
            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
              <Search className="text-gray-400" />
            </span>
            <Input
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10"
              placeholder={`Buscar por ${
                filterType === 'name' ? 'nome' : 'observação'
              } da região`}
            />
          </div>
          <Select
            value={filterType}
            onValueChange={(value: 'name' | 'observation') =>
              setFilterType(value)
            }
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Filtrar por" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="name">Nome</SelectItem>
              <SelectItem value="observation">Observação</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <CreateRegionDialog onRegionCreate={fetchRegions} />
      </div>
    </div>
  )

  return (
    <div className="min-h-screen">
      <Header filterContent={headerContent} />

      <div className="p-8">
        <div className="mx-auto max-w-4xl">
          <h1 className="mb-3 text-center text-2xl font-bold">
            Lista de Regiões
          </h1>
          <div className="mt-4">
            {filteredRegions.length > 0 ? (
              filteredRegions.map((region) => (
                <RegionCard
                  key={region.id}
                  id={region.id}
                  name={region.Name}
                  observation={region.Observation}
                  onRegionUpdate={fetchRegions}
                />
              ))
            ) : (
              <h2 className="mt-10">Não possui região cadastrada no sistema</h2>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
