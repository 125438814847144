import * as Toast from '@radix-ui/react-toast'
import { Pencil } from 'lucide-react'
import { useState } from 'react'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

import { Button } from '../ui/button'
import { Input } from '../ui/input'
import { Label } from '../ui/label'

interface UserEditDialogProps {
  id: number
  currentName: string
  currentEmail: string
  currentRole: 'BROKER' | 'ADMIN'
  onUserUpdate: () => void
}

export function UserEditDialog({
  id,
  currentName,
  currentEmail,
  currentRole,
  onUserUpdate,
}: UserEditDialogProps) {
  const [openModel, setOpenModel] = useState(false)
  const [name, setName] = useState(currentName)
  const [email, setEmail] = useState(currentEmail)
  const [role, setRole] = useState(currentRole)
  const [toastOpen, setToastOpen] = useState(false)
  const [toastType, setToastType] = useState('success')
  const [toastMessage, setToastMessage] = useState('')
  const [loading, setLoading] = useState(false)

  function handleToOpenModel() {
    setOpenModel(true)
    setName(currentName)
    setEmail(currentEmail)
    setRole(currentRole)
  }

  function handleRoleChange(newRole: 'ADMIN' | 'BROKER') {
    setRole(newRole)
  }

  async function handleSaveChanges() {
    const updatedUser = {
      username: name,
      email,
      collaborator: role,
    }

    setLoading(true)
    try {
      const route = import.meta.env.VITE_URL_ENDPOINT
      const response = await fetch(`${route}/api/users/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedUser),
      })

      if (!response.ok) {
        throw new Error('Failed to update user')
      }
      setOpenModel(false)
      onUserUpdate()
      setToastType('success')
      setToastMessage('Usuário editado com sucesso!')
      setToastOpen(true)
    } catch (error) {
      console.error('Failed to update user:', error)
      setToastOpen(true)
      setToastType('error')
      setToastMessage('Falha ao atualizar o usuário!')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Toast.Provider swipeDirection="right">
        <Dialog open={openModel} onOpenChange={setOpenModel}>
          <DialogTrigger asChild>
            <Button
              onClick={handleToOpenModel}
              className="rounded bg-gray-100 p-2 hover:bg-gray-200"
            >
              <Pencil className="text-gray-700" />
            </Button>
          </DialogTrigger>
          <DialogContent className="rounded-md bg-white p-4 shadow-lg sm:max-w-[425px]">
            <DialogHeader>
              <DialogTitle className="text-xl font-bold">
                Editar Usuário
              </DialogTitle>
              <DialogDescription className="text-sm text-gray-500">
                Após editar basta clicar em SALVAR
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Nome
                </Label>
                <Input
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="email" className="text-right">
                  Email
                </Label>
                <Input
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="col-span-3"
                />
              </div>
              <div className="flex w-full items-center justify-center gap-4">
                <Label htmlFor="role" className="text-right">
                  Cargo:
                </Label>
                <Button
                  className={`text-black ${role === 'ADMIN' ? 'bg-blue-600 hover:bg-blue-700' : 'bg-gray-300 hover:bg-gray-400'}`}
                  onClick={() => handleRoleChange('ADMIN')}
                >
                  Administrador
                </Button>
                <Button
                  className={`text-black ${role === 'BROKER' ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-300 hover:bg-gray-400'}`}
                  onClick={() => handleRoleChange('BROKER')}
                >
                  Corretor
                </Button>
              </div>
            </div>
            <DialogFooter>
              <Button
                type="button"
                onClick={handleSaveChanges}
                disabled={loading}
              >
                {loading ? 'Salvando...' : 'Salvar Alterações'}
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>

        <Toast.Root
          open={toastOpen}
          onOpenChange={setToastOpen}
          className={`fixed bottom-0 right-0 m-6 rounded-md p-4 shadow-md ${
            toastType === 'success'
              ? 'bg-green-500 text-white'
              : 'bg-red-500 text-white'
          }`}
        >
          <Toast.Description>{toastMessage}</Toast.Description>
          <Toast.Close className="absolute right-1 top-1" />
        </Toast.Root>
        <Toast.Viewport />
      </Toast.Provider>
    </div>
  )
}
